import React, { useMemo } from "react";
import { Box, Flex, Stack, Text, useBreakpointValue } from "@chakra-ui/react";
import Chart from "react-google-charts";
import { globalStyles } from "theme/styles";

type ChartProps = {
  chartData: any;
};

const PieChart: React.FC<ChartProps> = ({ chartData }) => {
  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR",
      }),
    []
  );
  const chartOptions: any = {
    chartArea: { left: 10, top: 10, right: 10, bottom: 10 },
    tooltip: { trigger: 'none' },
    fontName: "Source Sans Pro",
    colors: globalStyles.customColors.all,
    legend: { position: "none" },
    pieSliceText: "percentage"
  };

  const colors = chartOptions.colors || [];
  const responsiveWidth = useBreakpointValue({
    sm: "250px",    // for medium devices
    md: "400px",    // for larger screens
    lg: "330px",    // for even larger screens
  });

  const responsiveHeight = useBreakpointValue({
    sm: "280px",    // for medium devices
    md: "400px",    // for larger screens
    lg: "330px",    // for even larger screens
  });

  return (
    <Flex
      width="auto"
      align="flex-start"
      wrap="wrap"
    >
      <Box
        flex="1"
        minWidth={responsiveWidth}
        w={responsiveWidth}
        height={responsiveHeight}
      >
        <Chart
          chartType="PieChart"
          data={chartData}
          options={chartOptions}
          width="100%"
          height="100%"
        />
      </Box>

      <Box flex="0 0 30%" ml={4}>
        <Stack direction="column" spacing={2} width={60} ml={5} mt={10}>
          {chartData.slice(1).map(([strategyName, value, tooltip]: any, index: React.Key) => (
            <Flex key={index} align="center">
              <Box
                width="20px"
                height="20px"
                bg={colors[index.toString()] || "gray.500"}
                borderRadius="md"
                mr={2}
              />
              <Text fontSize={13}>
                <span style={{ fontWeight: 'bold' }}>{strategyName}</span>: {currencyFormatter.format(value)}
              </Text>
            </Flex>
          ))}
        </Stack>
      </Box>
    </Flex>
  );
};
export default PieChart;
