function getGenericArrayFromSessionStorage<Type>(key: string, typeInText: string, defaultReturn: Type[] = []): Type[] {
  const storedValue = sessionStorage.getItem(key);

  // If the value is null, return an empty array or handle the default case
  if (storedValue === null) {
    return defaultReturn;
  }

  try {
    const parsedValue = JSON.parse(storedValue);

    // Check if it's an array and if every item is a number
    if (Array.isArray(parsedValue) && parsedValue.every(item => typeof item === typeInText)) {
      return parsedValue;
    } else {
      console.warn(`Invalid format for key "${key}". Expected an array of ${typeInText}.`);
      return defaultReturn;
    }
  } catch (error) {
    console.error(`Error parsing value for key "${key}":`, error);
    return defaultReturn;
  }
}

export function getStringArrayFromSessionStorage(key: string, defaultReturn?: string[]): string[] {
  return getGenericArrayFromSessionStorage(key, 'string', defaultReturn);
}

export function getNumberArrayFromSessionStorage(key: string, defaultReturn?: number[]): number[] {
  return getGenericArrayFromSessionStorage(key, 'number', defaultReturn);
}

export function getDateFromSessionStorage(key: string): Date {
  const dateString = sessionStorage.getItem(key);

  if (!dateString) {
    return null; // Return null if no value in localStorage
  }

  const date = new Date(dateString);

  // Check if the parsed date is valid
  if (isNaN(date.getTime())) {
    return new Date();
  }

  return date;
}
