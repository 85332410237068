import { ChevronDownIcon, DownloadIcon } from "@chakra-ui/icons";
import { ExcelExport } from 'components/icons/Icons';
import { exportToExcel } from 'utils/ExcelExporter';
import {
  useDisclosure,
  Flex,
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
  Menu,
  MenuButton,
  MenuList,
  Grid,
  GridItem,
  MenuItem,
  Text,
} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  flexRender,
  SortingState,
} from "@tanstack/react-table";
import DatePickerSelect from "components/calendar/DatePickers";
import Card from "components/card/Card";
import { useEffect, useState } from "react";
import {
  MdFirstPage,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdLastPage,
  MdOutlineKeyboardArrowUp,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
import { RootState } from "redux/store";
import { ExportActionsToPerform, TranslationMappingSetting } from 'redux/slices/types';
type PageTableProps = {
  title: string;
  data: unknown[];
  columns: any[];
  downloadable: boolean;
  fileName?: string | null;
  translations: TranslationMappingSetting[];
  exportActions? : ExportActionsToPerform[];
};

export const PagedTable: React.FC<PageTableProps> = (props) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const headerBgColor = useColorModeValue("gray.100", "gray.900");

  const { title, data, columns, downloadable, fileName = "details.xlsx", translations,exportActions } = props;
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});

  useEffect(()=>{
    console.log(columns);
    setColumnVisibility({"StakeHolders":false,"StakeHolder":false});
  },[props])
  const table = useReactTable({
    data: data,
    columns, 
    state: {
      sorting,
      columnVisibility
    },
    defaultColumn:{ size:135, minSize :135, maxSize:135, enableResizing:false},
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
  });

  const exportExcel = () => {
    exportToExcel({ columns, data, fileName, translations,actionsOnRows: exportActions })
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedPageSize, setSelectedPageSize] = useState<number | null>(10);
  const itemsPerPage = [10, 20, 50, 100];
  const firstColumnWidth = "100px";

  return (

    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "auto", lg: "auto" }}
    >

      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          {title}
        </Text>
        <Text
          color={"green"}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          {downloadable && (
            <span
              onClick={exportExcel} // Click handler
              style={{ cursor: 'pointer', display: 'inline-block' }} // Pointer cursor and inline-block for proper sizing
            >
              <DownloadIcon w={4} h={4} color="grey" />
            </span>
          )}
        </Text>
        {/* <Menu /> */}
      </Flex>
      <Box maxHeight="400px" overflowY="auto" position="relative">
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead position="sticky" top={0} zIndex={3} bg={headerBgColor}>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header, columnIndex) => (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    pe="10px"
                    borderColor={borderColor}
                    cursor="pointer"
                    position={columnIndex === 0 ? "sticky" : "static"} // Stick the first column
                    top={0}
                    style={{minWidth:"135px",maxWidth:"135px", width:"135px"}}
                    left={
                      columnIndex === 0
                        ? 0
                        : columnIndex === 1
                          ? firstColumnWidth // Set left offset for second column
                          : `calc(${firstColumnWidth})` // Offset third and beyond columns
                    }
                    zIndex={columnIndex === 0 ? 2 : 1}
                    bg={headerBgColor}
                    overflow="hidden" // Ensure content doesn't overflow

                  >
                    <Flex
                      justifyContent="flex-start"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {header.isPlaceholder?null: flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {header.column.getIsSorted() === "asc" && (
                        <Box paddingLeft={2}>
                          <MdOutlineKeyboardArrowUp strokeWidth={1} />
                        </Box>
                      )}
                      {header.column.getIsSorted() === "desc" && (
                        <Box paddingLeft={2}>
                          <MdOutlineKeyboardArrowDown strokeWidth={1} />
                        </Box>
                      )}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id} position="relative" zIndex={1}>
                {row.getVisibleCells().map((cell, columnIndex) => (
                  <Td
                    key={cell.id}
                    fontSize={{ sm: "14px" }}
                    minW={{ sm: "135px", md: "135px", lg: "auto" }}
                    borderColor="transparent"
                    position={columnIndex === 0 ? "sticky" : "static"} // Stick the first column
                    left={
                      columnIndex === 0
                        ? 0
                        : columnIndex === 1
                          ? firstColumnWidth
                          : `calc(${firstColumnWidth})`
                    }
                    style={{minWidth:"135px",maxWidth:"135px",width:"135px"}}
                    zIndex={columnIndex === 0 ? 2 : 1} // Ensure first column is above other columns
                    bg={columnIndex === 0 ? "white" : "inherit"} // White background for sticky column
                    overflow="hidden" // Ensure content doesn't overflow
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Flex px="25px" mb="8px" justifyContent="center" align="center">
        <Button
          onClick={() => table.firstPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <MdFirstPage />
        </Button>
        <Button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <MdKeyboardArrowLeft />
        </Button>
        <Button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <MdKeyboardArrowRight />
        </Button>
        <Button
          onClick={() => table.lastPage()}
          disabled={!table.getCanNextPage()}
        >
          <MdLastPage />
        </Button>

        <Menu isOpen={isOpen} onClose={onClose}>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            sx={{ fontSize: 14, fontWeight: "normal" }}
            onClick={onOpen}
          >
            {selectedPageSize || "Results per page"}
          </MenuButton>
          <MenuList>
            <Grid templateColumns="repeat(1, 1fr)" gap={2} p={2}>
              {itemsPerPage.map((pageSize) => (
                <GridItem key={pageSize}>
                  <MenuItem
                    onClick={() => {
                      setSelectedPageSize(pageSize);
                      table.setPageSize(pageSize);
                    }}
                  >
                    {pageSize}
                  </MenuItem>
                </GridItem>
              ))}
            </Grid>
          </MenuList>
        </Menu>
      </Flex>

    </Card>
  );
};
