import { useSelector } from "react-redux";
import {
  Flex,
  Icon,
  Spinner,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { RootState } from "../../../../redux/store";
import { useEffect, useState } from "react";
import _ from "lodash";
import { MdBarChart } from "react-icons/md";
import IconBox from "components/icons/IconBox";
import StrategyCalculator from "utils/StrategyCalculator";

export default function DistributionsIndicator(props: {
  startContent?: JSX.Element;
  endContent?: JSX.Element;
  name: string;
  growth?: string | number;
  value: string | number;
  showHistoricalData: boolean;
}) {
  const { startContent, endContent, name, showHistoricalData } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";
  const { loading } = useSelector((state: RootState) => state.data);
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const selectedEntityIds = useSelector(
    (state: RootState) => state.data.selectedEntityIds
  );
  const stakeholders = useSelector(
    (state: RootState) => state.data.stakeholders
  );
  const strategyMappingSettings = useSelector(
    (state: RootState) => state.data.strategyMappingSettings
  );
  const loanGroupingMappingSettings = useSelector(
    (state: RootState) => state.data.loanGroupingMappingSettings
  );
  const correctionMappingSettings = useSelector(
    (state: RootState) => state.data.correctionMappingSettings
  );
  const selectedStrategyIds = useSelector(
    (state: RootState) => state.data.selectedStrategyIds
  );
  const selectedDate = useSelector(
    (state: RootState) => state.data.selectedDate
  );
  const currencyFormatter = new Intl.NumberFormat("nl-BE", {
    style: "currency",
    currency: "EUR",
  });

  const [totalDistributions, setTotalDistributions] = useState<
    number | undefined
  >(undefined);
  const [calculationDate, setCalculationDate] = useState<Date>(new Date());

  useEffect(() => {
    const calculationDate = selectedDate ? new Date(selectedDate) : new Date();
    setCalculationDate(calculationDate);
  }, [selectedDate]);
  useEffect(() => {
    const strategyCalculator = new StrategyCalculator(strategyMappingSettings);

    if (!loading && stakeholders && strategyMappingSettings) {
      if (stakeholders?.length) {
        const distributions = strategyCalculator.calculateGrossDistributions(
          stakeholders,
          selectedStrategyIds,
          calculationDate,
          loanGroupingMappingSettings?.values ?? [],
          correctionMappingSettings?.values ?? []
        );
        setTotalDistributions(distributions);
      }
    }
  }, [
    loading,
    stakeholders,
    strategyMappingSettings,
    totalDistributions,
    selectedStrategyIds,
    selectedDate,
    showHistoricalData,
    calculationDate,
    correctionMappingSettings,
    loanGroupingMappingSettings,
  ]);

  return (
    <Card>
      <Flex
        h="100%"
        align={{ base: "center", xl: "center" }}
        justify={{ base: "center", xl: "center" }}
        marginLeft="20px"
      >
        <IconBox
          w="56px"
          h="56px"
          bg={boxBg}
          icon={<Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />}
          marginRight="20px"
        />

        {startContent}
        <Stat my="auto" ms={startContent ? "18px" : "0px"} marginLeft="10px">
          <StatLabel
            lineHeight="100%"
            color={textColorSecondary}
            fontSize={{ base: "xl", xs: "xs" }}
          >
            {name}
          </StatLabel>
          <StatNumber color={textColor} fontSize={{ base: "xl" }}>
            {loading ? (
              <Spinner size="sm" />
            ) : totalDistributions !== undefined ? (
              currencyFormatter.format(totalDistributions)
            ) : (
              <Spinner size="sm" />
            )}
          </StatNumber>
        </Stat>
      </Flex>
    </Card>
  );
}
