import { ExportProps, TranslationMappingSetting } from 'redux/slices/types';
import * as XLSX from 'xlsx';

const translate = (action: string,translations:TranslationMappingSetting[] ) => {
  return (
    translations.find((x) => x.original === action)?.translated ?? action
  );
};
export const exportToExcel = ({ columns, data, fileName = 'export.xlsx',translations,actionsOnRows =[]}: ExportProps) => {
  // Map the headers
  const headers = columns
  .filter((col) => typeof col.header !== 'function') // Skip columns with function headers
  .map((col) => col.id);
  console.log(headers);
  // Map the row data according to accessorKey
  const rows = data.map((row) =>
    columns.reduce((acc, col) => {
      let cellValue = translate(row[col.accessorKey],translations);
      // Check if there's an action defined for this column
      const action = actionsOnRows?.find((action) => action.colId === col.id);
      if (action) {
        cellValue = action.actionToPerform(cellValue, row);
      }
      acc[col.id] = cellValue;
      return acc;
    }, {} as Record<string, any>)
  );
  // Create a worksheet and a workbook
  const worksheet = XLSX.utils.json_to_sheet(rows, { header: headers });
  // Calculate column widths based on header length
  const columnWidths = columns.map((col) => {
    const headerLength = col.id.length; // Length of the header text
    const dataLength = Math.max(...data.map((row) => (translate(row[col.accessorKey],translations)?.toString().trim().length || 0))); // Longest data length
    return { wch: Math.max(headerLength, dataLength)};
  });
  worksheet['!cols'] = columnWidths; // Set the column widths
  
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

 // Generate a Blob and trigger a download
 const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
 const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

 // Trigger download using an anchor element
 const url = window.URL.createObjectURL(blob);
 const a = document.createElement('a');
 a.href = url;
 a.download = fileName;
 document.body.appendChild(a);
 a.click();
 document.body.removeChild(a);
 window.URL.revokeObjectURL(url); // Clean up the URL
};

