// src/components/Default.tsx
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Flex,
  Icon,
  Spinner,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { RootState } from "../../../../redux/store";
import StrategyCalculator from "utils/StrategyCalculator";
import { MdShowChart } from "react-icons/md";
import IconBox from "components/icons/IconBox";

export default function InitialValueIndicator(props: {
  startContent?: JSX.Element;
  endContent?: JSX.Element;
  name: string;
  growth?: string | number;
  value: string | number;
  showHistoricalData: boolean;
}) {
  const { startContent, endContent, name, showHistoricalData} = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";
  const { loading } = useSelector((state: RootState) => state.data);
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

  const stakeholders = useSelector(
    (state: RootState) => state.data.stakeholders
  );
  const strategyMappingSettings = useSelector(
    (state: RootState) => state.data.strategyMappingSettings
  );
  const selectedStrategyIds = useSelector(
    (state: RootState) => state.data.selectedStrategyIds
  );
  const selectedDate = useSelector(
    (state: RootState) => state.data.selectedDate
  );
  const loanGroupingMappingSettings = useSelector(
    (state: RootState) => state.data.loanGroupingMappingSettings
  );
  const correctionMappingSettings = useSelector(
    (state: RootState) => state.data.correctionMappingSettings
  );
  const [totalInitialValue, setTotalInitialValue] = useState<
    number | undefined
  >(undefined);

  const strategyCalculator = useMemo(
    () => new StrategyCalculator(strategyMappingSettings),
    [strategyMappingSettings]
  );

  useEffect(() => {
    const calculationDate = selectedDate
    ? new Date(selectedDate)
    : new Date();

    if (!loading && stakeholders && strategyMappingSettings) {
      if (stakeholders?.length) {
        const initialValue = strategyCalculator.calculateInitialValue(
          stakeholders,
          selectedStrategyIds,
          calculationDate,
          loanGroupingMappingSettings?.values ?? [],
          correctionMappingSettings?.values ?? []
        );

        setTotalInitialValue(initialValue);
      }
    }
  }, [
    loading,
    selectedStrategyIds,
    stakeholders,
    strategyCalculator,
    strategyMappingSettings,
    showHistoricalData,
    selectedDate,
    correctionMappingSettings,
    loanGroupingMappingSettings
  ]);

  const currencyFormatter = new Intl.NumberFormat("nl-BE", {
    style: "currency",
    currency: "EUR",
  });

  return (
    <Card>
      <Flex
        my="auto"
        h="100%"
        align={{ base: "center", xl: "center" }}
        justify={{ base: "center", xl: "center" }}
        marginLeft='20px'

      >
        <IconBox w='56px'
          h='56px'
          bg={boxBg} icon={<Icon w='32px' h='32px' as={MdShowChart} color={brandColor} />}
          marginRight="20px"
        />
        {startContent}

        <Stat my="auto" ms={startContent ? "18px" : "0px"} marginLeft='10px'>
          <StatLabel
            lineHeight="100%"
            color={textColorSecondary}
            fontSize={{ base: "xl", xs: "xs" }}
          >
            {name}
          </StatLabel>
          <StatNumber color={textColor} fontSize={{ base: "xl" }}>
            {loading ? (
              <Spinner size="sm" />
            ) : totalInitialValue !== undefined ? (
              currencyFormatter.format(totalInitialValue)
            ) : (
              <Spinner size="sm" />
            )}
          </StatNumber>
        </Stat>
        <Flex ms="auto" w="max-content">
          {endContent}
        </Flex>
      </Flex>
    </Card>
  );
}
