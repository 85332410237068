// Chakra imports
import { Box } from "@chakra-ui/react";
// Layout components
import Navbar, { NavBarDomId } from "components/navbar/NavbarAdmin";
import Sidebar from "components/sidebar/Sidebar";
import { SidebarContext } from "contexts/SidebarContext";
import { msalInstance } from "index";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setOriginalEmail, setSelectedEmail } from "../../redux/slices/dataSlice";
import { AppDispatch } from "redux/store";
import routes from "routes/routes";
import { useDomElementListener } from "../../utils/useDOMElementListener";

type Props = PropsWithChildren


// Custom Chakra theme
export default function AdminLayout({children}: Props) {
  const accounts = msalInstance.getActiveAccount();
  const dispatch = useDispatch<AppDispatch>();
  const entry = useDomElementListener(NavBarDomId)

  useEffect(() => {
    // Clear session storage for entities if a different user logs in
    const storedAccount = sessionStorage.getItem("loggedInUser");
    if (storedAccount && storedAccount !== accounts?.username) {
      sessionStorage.removeItem("selectedEntities");
    }
    sessionStorage.setItem("loggedInUser", accounts?.username || "");

    const email = accounts?.username || "";
    dispatch(setOriginalEmail(email));
    dispatch(setSelectedEmail(email));
  }, [accounts?.username, dispatch]);

  // states and functions
  const [toggleSidebar, setToggleSidebar] = useState(false);
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes: RoutesType[]): string => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (window.location.pathname === routes[i].path) {
        return routes[i].name;
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  document.documentElement.dir = "ltr";

  const contentPaddingTop = useMemo(() => {
    if (!entry || !entry.borderBoxSize || !entry.borderBoxSize.length) {
      return 50
    }
    return entry.borderBoxSize[0].blockSize
  }, [entry]);


  return (
    <Box display="flex" flexDirection="row" minHeight="100vh">
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar routes={routes} display="none"/>
        <Box
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={{base: "100%", xl: "calc( 100% - 290px )"}}
          maxWidth={{base: "100%", xl: "calc( 100% - 290px )"}}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Navbar
            brandText={getActiveRoute(routes)}
            secondary={getActiveNavbar(routes)}
          />

          {getRoute() ? (
            <Box
              mx="auto"
              p={{base: "20px", md: "30px"}}
              pe="20px"
              minH="100vh"
              pt={{
                base: `${contentPaddingTop + 20}px`,
                md: `${contentPaddingTop + 30}px`
              }}
            >
              {children}
            </Box>
          ) : null}
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
