import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import StrategyCalculator from "utils/StrategyCalculator";
import { PagedTable } from "components/dataDisplay/PagedTable";
import { useQueryParams, StringParam } from "use-query-params";

type RowObj = {
  key?: string;
  strategy: string;
  strategyId: number;
  type: "SHARE" | "LOAN" | "MIXED";
  combinedStrategy: string;
  initialInvestmentDate: Date;
  initialValue: number;
  initialValueSHL: number;
  paid: number;
  toPay: number;
  distributions: number;
  actualValue: number;
  totalValue: number;
  mm: number;
  numberOfSecurities: number;
  nav: number;
  shareValuesPrices?: { [key: string]: number };
  stakeholders: string;
  secClassName: string;
};

export default function PortfolioDetails(props: {
  showHistoricalData: boolean;
}) {
  const { showHistoricalData } = props;
  const [query, setQuery] = useQueryParams({
    debug: StringParam,
  });
  const { debug } = query;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const stakeholders = useSelector(
    (state: RootState) => state.data.stakeholders
  );
  const { users, entitiesbyEmail } = useSelector(
    (state: RootState) => state.data
  );
  const strategyMappingSettings = useSelector(
    (state: RootState) => state.data.strategyMappingSettings
  );
  const loanGroupingMappingSettings = useSelector(
    (state: RootState) => state.data.loanGroupingMappingSettings
  );
  const correctionMappingSettings = useSelector(
    (state: RootState) => state.data.correctionMappingSettings
  );
  const selectedStrategyIds = useSelector(
    (state: RootState) => state.data.selectedStrategyIds
  );

  const numberFormatter = useMemo(() => new Intl.NumberFormat("nl-BE", {}), []);
  const dateFormatter = useMemo(() => new Intl.DateTimeFormat("nl-BE", {}), []);
  const excelFormatter = useMemo(
    () =>
      new Intl.DateTimeFormat("nl-BE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
    []
  );

  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR",
      }),
    []
  );
  const currencyFormatter4Decimals = useMemo(
    () =>
      new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 4,
      }),
    []
  );

  const securityTypeMappingSettings = useSelector(
    (state: RootState) => state.data.securityTypeMappingSettings
  );

  const securityClasses = useMemo(
    () =>
      securityTypeMappingSettings.securityTypes.filter((x) =>
        selectedStrategyIds.some((strategyId) =>
          x.strategyIds.includes(strategyId)
        )
      ),
    [securityTypeMappingSettings, selectedStrategyIds]
  );

  const selectedDate = useSelector(
    (state: RootState) => state.data.selectedDate
  );

  const columnHelper = createColumnHelper<RowObj>();
  const strategyCalculator = useMemo(
    () => new StrategyCalculator(strategyMappingSettings),
    [strategyMappingSettings]
  );
  const [calculationDate, setCalculationDate] = useState<Date>(new Date());

  const CreatePortfolioDetailRow = (
    rows: { [key: string]: RowObj },
    key: string,
    data: RowObj,
    shareValue: number
  ) => {
    if (!rows[key]) {
      rows[key] = {
        key: key,
        strategy: data.strategy,
        secClassName: data.secClassName,
        strategyId: -1, // it does not matter for the grouped rows
        type: "MIXED", // it does not matter for the grouped rows
        combinedStrategy: `${data.combinedStrategy}`,
        initialInvestmentDate: data.initialInvestmentDate,
        initialValue: 0,
        initialValueSHL: 0,
        paid: 0,
        toPay: 0,
        distributions: 0,
        actualValue: 0,
        totalValue: 0,
        mm: 0,
        numberOfSecurities: 0,
        nav: shareValue,
        stakeholders: data.stakeholders,
      };
    }

    // these values we can just add.
    rows[key].initialValue += data.initialValue;
    rows[key].initialValueSHL += data.initialValueSHL;
    rows[key].distributions += data.distributions;
    rows[key].actualValue += data.actualValue;
    rows[key].paid += data.paid;
    rows[key].toPay += data.toPay;
    rows[key].totalValue += data.totalValue;
    rows[key].numberOfSecurities += data.numberOfSecurities;

    // the values we need to recalculate per row.
    rows[key].initialInvestmentDate =
      data.initialInvestmentDate < rows[key].initialInvestmentDate
        ? data.initialInvestmentDate
        : rows[key].initialInvestmentDate;
    rows[key].mm = +(
      rows[key].totalValue / (rows[key].initialValue as number)
    ).toFixed(2);
  };

  useEffect(() => {
    if (showHistoricalData) {
      const calculationDate = selectedDate
        ? new Date(selectedDate)
        : new Date();
      setCalculationDate(calculationDate);
    } else {
      setCalculationDate(new Date());
    }
  }, [selectedDate, showHistoricalData]);

  const [ungroupedRows, setUngroupedRows] = useState<RowObj[]>([]);
  const [groupedRows, setGroupedRows] = useState<RowObj[]>([]);

  useEffect(() => {
    const strategyIdsThatApply = selectedStrategyIds.filter((strategyId) =>
      strategyCalculator.doesStrategyApply(strategyId, stakeholders)
    );

    const securityClassNamesByStrategy =
      strategyCalculator.getSecurityClassNamesForStrategies(
        strategyIdsThatApply,
        stakeholders,
        calculationDate,
        securityTypeMappingSettings.securityTypes ?? []
      );

    let rows: RowObj[] = [];

    securityClassNamesByStrategy.forEach((secClassByStrategy) => {
      const someRows = secClassByStrategy.securityClassNames
        ?.map((secClassName) => {
          const strategyName = strategyCalculator.getStrategyNameById(
            secClassByStrategy.strategyId
          );
          const strategyType = strategyCalculator.getStrategyType(
            secClassByStrategy.strategyId
          );
          const combinedStrategyName =
            strategyCalculator.getCombinedNameByStrategyId(
              secClassByStrategy.strategyId
            );
          const initialInvestmentDate =
            strategyCalculator.getOldestTransactionDate(
              stakeholders,
              [secClassByStrategy.strategyId],
              calculationDate
            );

          const initialValueBySec = strategyCalculator
            .calculateInitialValueBySecurityClass(
              stakeholders,
              [secClassByStrategy.strategyId],
              calculationDate,
              loanGroupingMappingSettings?.values ?? [],
              correctionMappingSettings?.values ?? []
            )
            .map((x) => {
              return {
                secClassName: strategyCalculator.translateSecurity(
                  secClassByStrategy.strategyId,
                  securityTypeMappingSettings.securityTypes ?? [],
                  x.secClassName
                ),
                initialValue: x.initialValue,
              };
            });

          const initialValue =
            initialValueBySec.find((x) => x.secClassName === secClassName)
              ?.initialValue ?? 0;

          const initialValueSHL = strategyCalculator.calculateInitialLoanValue(
            stakeholders,
            [secClassByStrategy.strategyId],
            calculationDate,
            loanGroupingMappingSettings?.values ?? []
          );
          const distributions = strategyCalculator.calculateGrossDistributions(
            stakeholders,
            [secClassByStrategy.strategyId],
            calculationDate,
            loanGroupingMappingSettings?.values ?? [],
            correctionMappingSettings?.values ?? []
          );
          const actualValueBySec = strategyCalculator
            .calculateActualValueBySecurityClass(
              stakeholders,
              [secClassByStrategy.strategyId],
              calculationDate,
              securityTypeMappingSettings.securityTypes ?? []
            )
            .map((x) => {
              return {
                secClassName: strategyCalculator.translateSecurity(
                  secClassByStrategy.strategyId,
                  securityTypeMappingSettings.securityTypes ?? [],
                  x.secClassName
                ),
                actualValue: x.actualValue,
              };
            });

          const actualValue =
            actualValueBySec.find((x) => x.secClassName === secClassName)
              ?.actualValue ?? 0;

          const unpaidValue = strategyCalculator.calculateUnpaidValue(
            stakeholders,
            secClassByStrategy.strategyId,
            calculationDate,
            secClassName,
            securityClasses
          );

          const paidValue = strategyCalculator.calculatePaidValue(
            stakeholders,
            secClassByStrategy.strategyId,
            calculationDate,
            secClassName,
            securityClasses
          );

          const numberOfSecuritiesForTransactions = strategyCalculator
            .calculateNumberOfSecuritiesForTransactionsBySecClass(
              stakeholders
                .flatMap((x) =>
                  x.shares.filter(
                    (s) => s.strategyId === secClassByStrategy.strategyId
                  )
                )
                .flatMap((x) => x.shareTransactions),
              calculationDate
            )
            .map((x) => {
              return {
                secClassName: strategyCalculator.translateSecurity(
                  secClassByStrategy.strategyId,
                  securityTypeMappingSettings.securityTypes ?? [],
                  x.secClassName
                ),
                amount: x.amount,
              };
            });

          const numberOfSecurities =
            numberOfSecuritiesForTransactions.find(
              (x) => x.secClassName === secClassName
            )?.amount ?? 0;

          const shareValuePrices =
            strategyCalculator.getShareValuePricesBySecurityClass(
              secClassByStrategy.strategyId,
              stakeholders,
              calculationDate,
              securityClasses
            );
          let applicableUsers =
            strategyCalculator.getStakeHoldersUserNameByStrategyId(
              stakeholders,
              entitiesbyEmail,
              secClassByStrategy.strategyId
            );
          const totalValue = actualValue + distributions;

          return {
            key: `${secClassByStrategy.strategyId}-${secClassName}`,
            secClassName: secClassName,
            strategy: strategyName,
            strategyId: secClassByStrategy.strategyId,
            type: strategyType,
            combinedStrategy: combinedStrategyName,
            initialInvestmentDate: initialInvestmentDate,
            initialValue: initialValue,
            initialValueSHL: initialValueSHL,
            numberOfSecurities: numberOfSecurities,
            paid: paidValue,
            toPay: unpaidValue,
            distributions: distributions,
            actualValue: actualValue,
            totalValue: totalValue,
            mm: +(totalValue / (initialValue as number)).toFixed(2),
            nav: 0,
            shareValuesPrices: shareValuePrices,
            stakeholders: applicableUsers,
          } as RowObj;
        })
        .filter((row) => row.initialInvestmentDate <= calculationDate); // Add this line to filter out rows with a later date.

      rows.push(...someRows);
    });

    setUngroupedRows(rows);
  }, [
    selectedStrategyIds,
    strategyCalculator,
    stakeholders,
    calculationDate,
    securityClasses,
    loanGroupingMappingSettings,
    correctionMappingSettings,
    users,
    securityTypeMappingSettings,
    entitiesbyEmail,
  ]);

  useEffect(() => {
    const rows: { [key: string]: RowObj } = {};
    const investmentsToHide = ["Commitment"];

    const priorityMap = {
      SHARE: 0,
      LOAN: 1,
      MIXED: 2,
    };

    ungroupedRows
      .sort((a, b) => priorityMap[a.type] - priorityMap[b.type])
      .forEach((data) => {
        if (Object.keys(data.shareValuesPrices).length > 0) {
          // We loop over the different share prices per security class. This means an investment can be added multiple times to the overview.
          Object.keys(data.shareValuesPrices).forEach((securityClass) => {
            const secClassName = strategyCalculator.translateSecurity(
              data.strategyId,
              securityClasses,
              securityClass
            );

            console.log(
              `*** creating row with strategyID ${data.strategyId} and secClass ${secClassName}`
            );

            if (data.secClassName === secClassName) {
              const key = `${
                data.combinedStrategy
              }-${strategyCalculator.translateSecurity(
                data.strategyId,
                securityClasses,
                securityClass
              )}`;
              const shareValue = data.shareValuesPrices[securityClass] ?? 0;

              CreatePortfolioDetailRow(rows, key, data, shareValue);
            }
          });
        } else {
          // if we hit the else, it is probably a LOAN.
          // as per requirements, the Loan should be counted to the first SHARE grouping.
          // there can be multiple SHARE groupings depending on the security classes.
          // if there are no other SHARE grouping, we create a new grouping with the combinedStrategy as name.
          const key = `${data.combinedStrategy}`;
          const firstShareKey = Object.keys(rows)
            .sort((a: string, b: string) => a.localeCompare(b))
            .find((x) => x.includes(key));

          CreatePortfolioDetailRow(rows, firstShareKey ?? key, data, 0);
        }
      });

    const sortedRows = Object.values(rows)
      .sort(
        (a, b) =>
          b.initialInvestmentDate.getTime() - a.initialInvestmentDate.getTime()
      )
      .filter((x) => !investmentsToHide.includes(x.combinedStrategy)); // filter the rows we do not want to see.

    setGroupedRows(sortedRows);
  }, [securityClasses, strategyCalculator, ungroupedRows]);

  const translateSecurity = useCallback(
    (strategyId: number, security: string) => {
      const translation = strategyCalculator.translateSecurity(
        strategyId,
        securityClasses,
        security
      );

      if (translation === "loan-without-share") return "-";

      return translation;
    },
    [securityClasses, strategyCalculator]
  );

  const columns = [
    columnHelper.accessor("combinedStrategy", {
      id: "Investment",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("initialInvestmentDate", {
      id: "Initial Investment Date",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {dateFormatter.format(info.getValue())}
        </Text>
      ),
    }),

    columnHelper.accessor("initialValue", {
      id: "Total Initial Investment",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("initialValueSHL", {
      id: "Initial Value SHL",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("paid", {
      id: "Shares Paid Up",
      size: 40,
      enableResizing: false,
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("toPay", {
      id: "Shares To Pay Up",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("numberOfSecurities", {
      id: "Number of shares",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {numberFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("nav", {
      id: "NAV",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue() === 0
            ? "-"
            : currencyFormatter4Decimals.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("secClassName", {
      id: "Type of security",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => {
        const rowData = info.row.original; // The entire original data object for this row
        const strategyId = rowData.strategyId; // Another field from the same row
        return (
          <Text color={textColor} fontSize="sm" fontWeight="400">
            {translateSecurity(strategyId, info.getValue())}
          </Text>
        );
      },
    }),
    columnHelper.accessor("actualValue", {
      id: "Actual Value",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="400">
            {currencyFormatter.format(info.getValue())}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("distributions", {
      id: "Distributions",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("totalValue", {
      id: "Total Value",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("mm", {
      id: "MM",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue()}
          {info.getValue() === Infinity ? "" : "x"}
        </Text>
      ),
    }),
    columnHelper.accessor("stakeholders", {
      id: "StakeHolders",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue()}
        </Text>
      ),
    }),
  ];

  const debugColumns = [
    columnHelper.accessor("key", {
      id: "key",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue()}
        </Text>
      ),
    }),
    ...columns,
  ];

  const formatExcel = () => {
    return excelFormatter
      .formatToParts(Date.now())
      .filter((m) => m.type !== "literal")
      .reverse()
      .map((p) => p.value)
      .join("");
  };
  return (
    <>
      {debug === "true" ? (
        <PagedTable
          title="Portfolio Details (ungrouped)"
          columns={debug === "true" ? debugColumns : columns}
          downloadable={true}
          fileName={`PortfolioDetails${formatExcel()}.xlsx`}
          translations={[]}
          data={ungroupedRows}
        />
      ) : null}
      <PagedTable
        title="Portfolio Details"
        columns={debug === "true" ? debugColumns : columns}
        downloadable={true}
        fileName={`PortfolioDetails${formatExcel()}.xlsx`}
        translations={[]}
        data={groupedRows}
      />
    </>
  );
}
