import { Text, useColorModeValue } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import StrategyCalculator from "utils/StrategyCalculator";
import { PagedTable } from "components/dataDisplay/PagedTable";
import {
  ExportActionsToPerform,
  LoanCalculationStepDetails,
} from "redux/slices/types";
import { StringParam, useQueryParams } from "use-query-params";

interface SHLCalculationStep extends LoanCalculationStepDetails {
  strategy: string;
  investment: string;
  compartiment: string;
  stakeholders: string;
}

export default function SHLOverview(props: { showHistoricalData: boolean }) {
  const { showHistoricalData } = props;
  const [query, setQuery] = useQueryParams({
    debug: StringParam,
  });
  const { debug } = query;

  const stakeholders = useSelector(
    (state: RootState) => state.data.stakeholders
  );

  const strategyMappingSettings = useSelector(
    (state: RootState) => state.data.strategyMappingSettings
  );

  const loanGroupingMappingSettings = useSelector(
    (state: RootState) => state.data.loanGroupingMappingSettings
  );

  const correctionMappingSettings = useSelector(
    (state: RootState) => state.data.correctionMappingSettings
  );

  const selectedStrategyIds = useSelector(
    (state: RootState) => state.data.selectedStrategyIds
  );

  const selectedDate = useSelector(
    (state: RootState) => state.data.selectedDate
  );

  const translations = useSelector((state: RootState) =>
    state.data.translationMappingSettings.transactions.filter(
      (x) => x.type === "SHL"
    )
  );

  const { users, entitiesbyEmail } = useSelector(
    (state: RootState) => state.data
  );

  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR",
      }),
    []
  );

  const dateFormatter = useMemo(() => new Intl.DateTimeFormat("nl-BE", {}), []);
  const numberDecimalFormatter = useMemo(
    () =>
      new Intl.NumberFormat("nl-BE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    []
  );

  const columnHelper = createColumnHelper<SHLCalculationStep>();
  const strategyCalculator = useMemo(
    () => new StrategyCalculator(strategyMappingSettings),
    [strategyMappingSettings]
  );

  const [calculationDate, setCalculationDate] = useState<Date>(new Date());

  useEffect(() => {
    if (showHistoricalData) {
      const calculationDate = selectedDate
        ? new Date(selectedDate)
        : new Date();
      setCalculationDate(calculationDate);
    } else {
      setCalculationDate(new Date());
    }
  }, [selectedDate, showHistoricalData]);

  const transactionRows = useMemo<SHLCalculationStep[]>(() => {
    const rows: SHLCalculationStep[] = [];

    selectedStrategyIds.forEach((strategyId) => {
      const strategyName = strategyCalculator.getStrategyNameById(strategyId);
      const investment =
        strategyCalculator.getCombinedNameByStrategyId(strategyId);
      const compartiment = strategyCalculator.getDetailedNameByStrategyId(
        strategyId,
        "LOAN"
      );

      stakeholders.forEach((st) => {
        console.log(`SHL Overview Stakeholder: ${st.ownerId}`);

        const loanStepDetails = strategyCalculator.calculateLoanStepDetails(
          strategyId,
          st,
          calculationDate,
          loanGroupingMappingSettings.values ?? [],
          correctionMappingSettings.values ?? []
        );
        loanStepDetails.forEach((loanStepDetail) => {
          rows.push({
            ...loanStepDetail,
            strategy: strategyName,
            investment: investment,
            compartiment: compartiment,
            stakeholders: strategyCalculator.getUserNameByStakeHolderId(
              st.ownerId,
              entitiesbyEmail
            ),
          });
        });
      });
    });

    return rows.sort((a, b) => b.date.getTime() - a.date.getTime());
  }, [
    calculationDate,
    correctionMappingSettings,
    entitiesbyEmail,
    loanGroupingMappingSettings,
    selectedStrategyIds,
    stakeholders,
    strategyCalculator,
  ]);

  const translateTransaction = (transaction: string) => {
    return (
      translations.find((x) => x.original === transaction)?.translated ??
      transaction
    );
  };

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const columns = [
    columnHelper.accessor("investment", {
      id: "Investment",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("compartiment", {
      id: "Compartiment",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("transaction", {
      id: "Transaction",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {translateTransaction(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("date", {
      id: "Date",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {dateFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("cashFlow", {
      id: "Cashflow",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("interest", {
      id: "Interest (%)",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {numberDecimalFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("grossAmountTransaction", {
      id: "Gross Amount Transaction",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("rv", {
      id: "RV (%)",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue() != null
            ? numberDecimalFormatter.format(info.getValue())
            : "-"}
        </Text>
      ),
    }),
    columnHelper.accessor("rvEuro", {
      id: "RV (Euro)",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue() != null
            ? currencyFormatter.format(info.getValue())
            : "-"}
        </Text>
      ),
    }),
    columnHelper.accessor("netAmountTransaction", {
      id: "Net Amount Transaction",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("totalOutstandingAmountPost", {
      id: "Total Outstanding Amount (Post Transaction)",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("stakeholders", {
      id: "StakeHolder",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue()}
        </Text>
      ),
    }),
  ];

  const debugColumns = [
    columnHelper.accessor("loanId", {
      id: "loanId",
      header: (table) => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          {table.column.id}
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue()}
        </Text>
      ),
    }),
    ...columns,
  ];

  const actions = (): ExportActionsToPerform[] => {
    return [
      {
        colId: "RV (%)",
        actionToPerform: (value: number) => {
          return value / 100;
        },
      },
      {
        colId: "Interest (%)",
        actionToPerform: (value: number) => {
          return value / 100;
        },
      },
    ];
  };
  const excelFormatter = useMemo(
    () =>
      new Intl.DateTimeFormat("nl-BE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
    []
  );

  const formatExcel = () => {
    return excelFormatter
      .formatToParts(Date.now())
      .filter((m) => m.type !== "literal")
      .reverse()
      .map((p) => p.value)
      .join("");
  };

  return (
    <PagedTable
      title="SHL Overview"
      downloadable={true}
      fileName={`SHLOverview${formatExcel()}.xlsx`}
      translations={translations}
      columns={debug === "true" ? debugColumns : columns}
      data={transactionRows}
      exportActions={actions()}
    />
  );
}
