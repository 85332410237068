import _ from "lodash";
import {
  CorrectionMappingSetting,
  LoanCalculationStepDetails,
  LoanGroupMappingSetting,
  SecurityTypeMappingSetting,
  Stakeholder,
  StakeholderLoan,
  StakeholderLoanCalculationStepWithLoan,
  StakeholderShare,
  StakeholderShareTransaction,
  StrategyMapping,
} from "redux/slices/types";

class StrategyCalculator {
  strategyMappingSettings: StrategyMapping;
  transactionTypesToAddSecurities = [
    "ISSUE_SECURITY",
    "CREATE_SECURITY",
    "TRANSFER_SECURITY",
  ];
  transactionTypesToSubtractSecurities = ["CANCEL_SECURITY"];

  constructor(strategyMappingSettings: StrategyMapping) {
    this.strategyMappingSettings = strategyMappingSettings;
  }
  calculateNumberOfSecuritiesForTransactionsBySecClass = (
    transactions: StakeholderShareTransaction[],
    currentDateTime: Date
  ): { secClassName: string; amount: number }[] => {
    const amountToAdd = this.getNumberOfSecuritiesBySecClassAndTransactionType(
      transactions,
      currentDateTime,
      this.transactionTypesToAddSecurities
    );
    const amountToSubtract =
      this.getNumberOfSecuritiesBySecClassAndTransactionType(
        transactions,
        currentDateTime,
        this.transactionTypesToSubtractSecurities
      );

    const totalNumberOfSecuritiesBySecClasses: {
      secClassName: string;
      amount: number;
    }[] = [];

    // These will added (+=) from the amount, per security class name.
    amountToAdd.forEach((amount) => {
      var totalBySecClass = totalNumberOfSecuritiesBySecClasses.find(
        (x) => x?.secClassName === amount?.secClassName
      );
      if (totalBySecClass) {
        totalBySecClass.amount += amount?.amount ?? 0;
      } else {
        const newBySecClass: { secClassName: string; amount: number } = {
          secClassName: amount?.secClassName,
          amount: amount?.amount ?? 0,
        };

        totalNumberOfSecuritiesBySecClasses.push(newBySecClass);
      }
    });

    // These will subtracted (-=) from the amount, per security class name.
    amountToSubtract.forEach((amount) => {
      var totalBySecClass = totalNumberOfSecuritiesBySecClasses.find(
        (x) => x?.secClassName === amount?.secClassName
      );
      if (totalBySecClass) {
        totalBySecClass.amount -= amount?.amount ?? 0;
      } else {
        const newBySecClass: { secClassName: string; amount: number } = {
          secClassName: amount?.secClassName,
          amount: amount?.amount ?? 0,
        };

        totalNumberOfSecuritiesBySecClasses.push(newBySecClass);
      }
    });

    return totalNumberOfSecuritiesBySecClasses;
  };
  getNumberOfSecuritiesBySecClassAndTransactionType(
    transactions: StakeholderShareTransaction[],
    currentDateTime: Date,
    transactionTypes: string[]
  ): { secClassName: string; amount: number }[] {
    const filteredTransactions = transactions
      ?.filter((x) => transactionTypes.includes(x.type))
      ?.filter(
        (x) => new Date(x.transactionDate).getTime() < currentDateTime.getTime()
      );

    const totalNumberOfSecuritiesBySecClasses: {
      secClassName: string;
      amount: number;
    }[] = [];

    filteredTransactions.forEach((transaction) => {
      var totalBySecClass = totalNumberOfSecuritiesBySecClasses.find(
        (x) => x.secClassName === transaction?.holder?.securityClass
      );
      if (totalBySecClass) {
        totalBySecClass.amount += transaction?.holder.amount;
      } else {
        const newBySecClass: { secClassName: string; amount: number } = {
          secClassName: transaction?.holder.securityClass,
          amount: transaction?.holder.amount,
        };

        totalNumberOfSecuritiesBySecClasses.push(newBySecClass);
      }
    });

    return totalNumberOfSecuritiesBySecClasses;
  }
  getStakeHoldersUserNameByStrategyId(
    stakeholders: Stakeholder[],
    users: any[],
    strategyId: number
  ) {
    let applicableStakeHolders: Stakeholder[] = [];
    stakeholders.forEach((st) => {
      st.shares.forEach((share) => {
        if (strategyId === share.strategyId) {
          applicableStakeHolders.push(st);
        }
      });
      st.loanShares.forEach((share) => {
        if (strategyId === share.strategyId) {
          applicableStakeHolders.push(st);
        }
      });
      st.loans.forEach((loan) => {
        if (strategyId === loan.strategyId) {
          applicableStakeHolders.push(st);
        }
      });
      st.commitments.forEach((commitment) => {
        if (strategyId === commitment.strategyId) {
          applicableStakeHolders.push(st);
        }
      });
      st.certificates.forEach((cert) => {
        if (strategyId === cert.strategyId) {
          applicableStakeHolders.push(st);
        }
      });
    });
    console.log("my users", users);
    var distinctStakeHolders = Array.from(
      new Map(
        applicableStakeHolders.map((st) => [
          `${st.ownerId}-${st.partitionKey}`, // Combine `ownerId` and `partitionKey` as a unique key
          st,
        ])
      ).values()
    );
    return distinctStakeHolders
      ? users
          .filter((u) =>
            distinctStakeHolders.map((x) => x.ownerId).includes(u.entityId)
          )
          .map((u) => {
            return u.entityType === "PERSON"
              ? `${u.firstName} ${u.name}`
              : u.entityName;
          })
          .join(",")
      : "";
  }

  getUserNameByStakeHolderId = (stakeholderId: number, users: any[]) => {
    console.log(users);
    return users
      .filter((u) => u.entityId == stakeholderId)
      .map((u) =>
        u.entityType === "PERSON" ? `${u.firstName} ${u.name}` : u.entityName
      )
      .join(",");
  };

  getStrategyNameById = (strategyId: number) => {
    for (const strategy of this.strategyMappingSettings.values) {
      for (const combined of strategy.combined) {
        if (
          combined.values.some(
            (value: { entityId: number }) => value.entityId === strategyId
          )
        ) {
          return strategy.strategy;
        }
      }
    }
    return null;
  };
  getCombinedNameByStrategyId = (strategyId: number) => {
    const combined = this.strategyMappingSettings?.values
      .flatMap((x) => x.combined)
      .find((x) => x.values.some((value) => value.entityId === strategyId));
    return combined?.name;
  };
  getDetailedNameByStrategyId = (strategyId: number, type: string) => {
    const detailed = this.strategyMappingSettings?.values
      .flatMap((x) => x.combined)
      .flatMap((x) => x.values)
      .find((x) => x.entityId === strategyId && x.type === type);
    return detailed?.investment;
  };
  printStrategyCombinedName = (strategyId: number): string => {
    const combined = this.strategyMappingSettings?.values
      .flatMap((x) => x.combined)
      .find((x) => x.values.some((value) => value.entityId === strategyId));
    return `${combined?.name} - ${strategyId}`;
  };
  getLoanType = (strategyId: number) => {
    const loanType = this.strategyMappingSettings.values
      .flatMap((x) => x.combined)
      .flatMap((x) => x.values)
      .find((x) => x.entityId === strategyId && x.type === "LOAN")?.loanType;

    return loanType;
  };
  getStrategyType = (strategyId: number) => {
    const strategyType = this.strategyMappingSettings.values
      .flatMap((x) => x.combined)
      .flatMap((x) => x.values)
      .find((x) => x.entityId === strategyId)?.type;

    return strategyType;
  };
  getStrategyPrices = (strategyId: number, type: string, priceType: string) => {
    const shareValuePrices = this.strategyMappingSettings.values
      .flatMap((x) => x.combined)
      .flatMap((x) => x.values)
      .find((x) => x.entityId === strategyId && x.type === type)?.prices;

    const normalPrices = shareValuePrices?.filter((x) => x.type === "Normal");
    const pricesForPriceType = shareValuePrices?.filter(
      (x) => x.type === priceType
    );

    // console.log(
    //   `Actual value strategy price for strategy ${strategyId} + type '${priceType}': `,
    //   pricesForPriceType
    // );
    // console.log(
    //   `Actual value strategy price for strategy ${strategyId} + type 'Normal': `,
    //   normalPrices
    // );

    // return the prices for the specified price type (which would be a security class name), or default to the prices with type === 'Normal'.
    return pricesForPriceType?.length ? pricesForPriceType : normalPrices;
  };
  calculateActualValue = (
    stakeholders: Stakeholder[],
    selectedStrategyIds: number[],
    currentDateTime: Date,
    securityClasses: SecurityTypeMappingSetting[]
  ): number => {
    let sumActualValueShares = 0;
    let sumActualValueLoans = 0;

    console.log(
      `--- START OF ACTUAL VALUE CALCULATION (${currentDateTime}) ---`
    );

    stakeholders.forEach((st) => {
      console.log(`Actual value calculation Stakeholder ${st.ownerId}:`);

      // calculate the actual value for shares.
      st.shares.forEach((share) => {
        if (!selectedStrategyIds.includes(share.strategyId)) {
          return;
        }
        var value = this.calculateActualShareValue(
          share,
          currentDateTime,
          securityClasses
        );
        sumActualValueShares += value;
      });

      // calculate the actual value for loans.
      sumActualValueLoans += this.calculateActualLoansValue(
        st.loans,
        selectedStrategyIds,
        currentDateTime
      );
    });

    console.log(
      `Sum of shares: ${sumActualValueShares} + sum of loans: ${sumActualValueLoans} = ${
        sumActualValueShares + sumActualValueLoans
      }`
    );
    console.log(`--- END OF ACTUAL VALUE CALCULATION ---`);

    // return the sum of shares and loans.
    return sumActualValueShares + sumActualValueLoans;
  };

  calculateActualValueBySecurityClass = (
    stakeholders: Stakeholder[],
    selectedStrategyIds: number[],
    currentDateTime: Date,
    securityClasses: SecurityTypeMappingSetting[]
  ): {
    secClassName: string;
    actualValue: number;
  }[] => {
    const actualShareValueBySecClasses: {
      secClassName: string;
      actualValue: number;
    }[] = [];

    console.log(
      `--- START OF ACTUAL VALUE CALCULATION (${currentDateTime}) BY SECURITY CLASS ---`
    );

    stakeholders.forEach((st) => {
      console.log(`Actual value calculation Stakeholder ${st.ownerId}:`);

      // calculate the actual value for shares.
      st.shares.forEach((share) => {
        if (!selectedStrategyIds.includes(share.strategyId)) {
          return;
        }
        var value = this.calculateActualShareValueBySecurityClass(
          share,
          currentDateTime,
          securityClasses
        );

        value.forEach((actualShareValueBySecClass) => {
          var totalBySecClass = actualShareValueBySecClasses.find(
            (x) => x?.secClassName === actualShareValueBySecClass?.secClassName
          );

          if (totalBySecClass) {
            totalBySecClass.actualValue +=
              actualShareValueBySecClass.actualShareValue ?? 0;
          } else {
            const newBySecClass: {
              secClassName: string;
              actualValue: number;
            } = {
              secClassName: actualShareValueBySecClass?.secClassName,
              actualValue: actualShareValueBySecClass.actualShareValue ?? 0,
            };

            actualShareValueBySecClasses.push(newBySecClass);
          }
        });
      });

      // calculate the actual value for loans.
      const actualLoansValue = this.calculateActualLoansValue(
        st.loans,
        selectedStrategyIds,
        currentDateTime
      );

      console.log(`Actual value loans ${actualLoansValue}`);

      // we'll add the loans actual value with the first security class row
      if (actualShareValueBySecClasses?.length) {
        _.first(actualShareValueBySecClasses).actualValue += actualLoansValue;
      } else {
        const newBySecClass: {
          secClassName: string;
          actualValue: number;
        } = {
          secClassName: "loan-without-share",
          actualValue: actualLoansValue,
        };

        actualShareValueBySecClasses.push(newBySecClass);
      }
    });

    console.log(`--- END OF ACTUAL VALUE CALCULATION BY SECURITY CLASS ---`);

    return actualShareValueBySecClasses;
  };

  calculateActualLoansValue = (
    loans: StakeholderLoan[],
    selectedStrategyIds: number[],
    currentDateTime: Date
  ): number => {
    let sumLoanTotalOutstandingAmount = 0;

    // we group the loans by strategy id, because there can be multiple loans which are actually still the same loan.
    // The same loans are indicated by the same strategy id.
    // For calculating the actual value, we group the loans and take the loan with the latest startDate.
    const actualLoans = _.chain(loans)
      .filter((loan) => {
        // The loan should be in the selected strategies.
        if (!selectedStrategyIds.includes(loan.strategyId)) {
          return false;
        }
        // The loan should have a start date before the current date.
        if (new Date(loan.startDate).getTime() > currentDateTime.getTime()) {
          return false;
        }
        
        // -------------------------
        // MICHIEL ZEGT TEGEN ZICHZELF: 
        // THE COMMENTED CODE IS BAD, BECAUSE IT FILTERS LOANS, WHICH HAVE NO CALCULATION STEPS, BUT THE LOAN IS STILL NEEDED FOR THE PRINCIPAL AMOUNT
        // -------------------------
        // The loans with calculation steps that have a date after the current date are ignored.
        // var allLoanCalculationsAndStepsAreInFuture =
        //   loan.loanCalculations.every((loanCalculation) =>
        //     loanCalculation.calculationSteps.every(
        //       (step) => new Date(step.date) > currentDateTime
        //     )
        //   );
        // if (allLoanCalculationsAndStepsAreInFuture) {
        //   return false;
        // }

        return true;
      })
      .groupBy((x) => x.strategyId)
      .map((loans, strategyId) => ({
        strategyId,
        loan: _.maxBy(
          loans.filter((loan) => new Date(loan.startDate) < currentDateTime),
          (loan) => new Date(loan.startDate)
        ),
      }))
      .value()
      .map((x) => x.loan);

    actualLoans.forEach((loan) => {
      const loanValue = this.calculateActualLoanValue(loan, currentDateTime);
      sumLoanTotalOutstandingAmount += loanValue;
    });

    return sumLoanTotalOutstandingAmount;
  };
  calculateActualShareValue = (
    share: StakeholderShare,
    currentDateTime: Date,
    securityClasses: SecurityTypeMappingSetting[]
  ): number => {
    const actualShareValueBySecurityClass =
      this.calculateActualShareValueBySecurityClass(
        share,
        currentDateTime,
        securityClasses
      );
    let actualShareValue = 0;

    actualShareValueBySecurityClass.forEach((actualValueBySecClass) => {
      actualShareValue += actualValueBySecClass.actualShareValue;
    });

    return actualShareValue;
  };
  calculateActualShareValueBySecurityClass = (
    share: StakeholderShare,
    currentDateTime: Date,
    securityClasses: SecurityTypeMappingSetting[]
  ): {
    secClassName: string;
    actualShareValue: number;
  }[] => {
    const numberOfSecuritiesBySecClass =
      this.calculateNumberOfSecuritiesForTransactionsBySecClass(
        share.shareTransactions,
        currentDateTime
      );

    const actualShareValueBySecClasses: {
      secClassName: string;
      actualShareValue: number;
    }[] = [];

    numberOfSecuritiesBySecClass.forEach((amountBySecClass) => {
      const shareValuePrices = this.getStrategyPrices(
        share.strategyId,
        "SHARE",
        this.translateSecurity(
          share.strategyId,
          securityClasses,
          amountBySecClass.secClassName
        )
      );

      if (shareValuePrices) {
        const sortedPrices = shareValuePrices
          .filter(
            (x) =>
              new Date(x.calculationDate).getTime() <= currentDateTime.getTime()
          )
          .sort(
            (a, b) =>
              new Date(b.calculationDate).getTime() -
              new Date(a.calculationDate).getTime()
          );
        const currentShareValuePrice = _.first(sortedPrices)?.price ?? 0;

        console.log(
          `Actual Value SHARE ${this.printStrategyCombinedName(
            share.strategyId
          )} = ${
            amountBySecClass.amount
          } securities * € ${currentShareValuePrice} (current price) for Security Class ${
            amountBySecClass.secClassName
          } = ${amountBySecClass.amount * currentShareValuePrice}`
        );

        const value = amountBySecClass.amount * currentShareValuePrice;

        var totalBySecClass = actualShareValueBySecClasses.find(
          (x) => x?.secClassName === amountBySecClass?.secClassName
        );

        if (totalBySecClass) {
          totalBySecClass.actualShareValue += value ?? 0;
        } else {
          const newBySecClass: {
            secClassName: string;
            actualShareValue: number;
          } = {
            secClassName: amountBySecClass?.secClassName,
            actualShareValue: value ?? 0,
          };

          actualShareValueBySecClasses.push(newBySecClass);
        }
      }
    });

    return actualShareValueBySecClasses;
  };
  calculateActualLoanValue = (
    loan: StakeholderLoan,
    currentDateTime: Date
  ): number => {
    console.log("michi loan", loan);

    const latestCalculation = _.first(
      loan.loanCalculations
        .filter(
          (x) => new Date(x.validFrom).getTime() < currentDateTime.getTime()
        )
        .sort(
          (a, b) =>
            new Date(b.validFrom).getTime() - new Date(a.validFrom).getTime()
        )
    );
    let loanValue = 0;

    if (
      latestCalculation &&
      latestCalculation.calculationSteps?.filter(
        (x) => new Date(x.date).getTime() < currentDateTime.getTime()
      ).length
    ) {
      const latestCalculationStep = _.first(
        latestCalculation.calculationSteps
          .filter((x) => new Date(x.date).getTime() < currentDateTime.getTime())
          .sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
          )
      );

      loanValue = latestCalculationStep?.totalOutstandingAmount ?? 0;

      console.log(
        `LOAN ${this.printStrategyCombinedName(
          loan.strategyId
        )} = totalOutstandingAmount ${
          latestCalculationStep?.totalOutstandingAmount ?? 0
        }`
      );
    } else if (latestCalculation) {
      loanValue = latestCalculation?.outstandingPrincipalAmount ?? 0;

      console.log(
        `LOAN ${this.printStrategyCombinedName(
          loan.strategyId
        )} = outstandingPrincipalAmount ${
          latestCalculation?.outstandingPrincipalAmount ?? 0
        }`
      );
    } else {
      loanValue = loan?.principalAmount ?? 0;

      console.log(
        `LOAN ${this.printStrategyCombinedName(
          loan.strategyId
        )} = principalAmount of Loan, because no calculations ${
          loan?.principalAmount ?? 0
        }`
      );
    }

    return loanValue;
  };
  calculateInitialValue = (
    stakeholders: Stakeholder[],
    selectedStrategyIds: number[],
    currentDateTime: Date,
    loanGroupingMappingSettings: LoanGroupMappingSetting[],
    correctionMappingSettings: CorrectionMappingSetting[]
  ): number => {
    console.log(`--- START OF INITIAL VALUE CALCULATION ---`);

    let sumShares = this.calculateInitialShareValue(
      stakeholders,
      selectedStrategyIds,
      currentDateTime,
      correctionMappingSettings
    );
    let sumLoans = this.calculateInitialLoanValue(
      stakeholders,
      selectedStrategyIds,
      currentDateTime,
      loanGroupingMappingSettings
    );

    const initialValue = sumShares + sumLoans;

    console.log(
      `Sum of shares: ${sumShares} + sum of loans: ${sumLoans} = ${initialValue}`
    );
    console.log(`--- END OF INITIAL VALUE CALCULATION ---`);

    return initialValue;
  };
  calculateInitialValueBySecurityClass = (
    stakeholders: Stakeholder[],
    selectedStrategyIds: number[],
    currentDateTime: Date,
    loanGroupingMappingSettings: LoanGroupMappingSetting[],
    correctionMappingSettings: CorrectionMappingSetting[]
  ): {
    secClassName: string;
    initialValue: number;
  }[] => {
    console.log(`--- START OF INITIAL VALUE CALCULATION ---`);

    const initialValueBySecClasses: {
      secClassName: string;
      initialValue: number;
    }[] = [];

    let initialShareValueBySecurityClasses =
      this.calculateInitialShareValueBySecurityClass(
        stakeholders,
        selectedStrategyIds,
        currentDateTime,
        correctionMappingSettings
      );

    initialShareValueBySecurityClasses.forEach(
      (initialShareValueBySecClass) => {
        var totalBySecClass = initialValueBySecClasses.find(
          (x) => x?.secClassName === initialShareValueBySecClass?.secClassName
        );

        if (totalBySecClass) {
          totalBySecClass.initialValue +=
            initialShareValueBySecClass.initialShareValue ?? 0;
        } else {
          const newBySecClass: {
            secClassName: string;
            initialValue: number;
          } = {
            secClassName: initialShareValueBySecClass?.secClassName,
            initialValue: initialShareValueBySecClass.initialShareValue ?? 0,
          };

          initialValueBySecClasses.push(newBySecClass);
        }
      }
    );

    let sumLoans = this.calculateInitialLoanValue(
      stakeholders,
      selectedStrategyIds,
      currentDateTime,
      loanGroupingMappingSettings
    );

    // we'll add the loans initial value with the first security class row
    if (initialValueBySecClasses?.length) {
      _.first(initialValueBySecClasses).initialValue += sumLoans;
    } else {
      const newBySecClass: {
        secClassName: string;
        initialValue: number;
      } = {
        secClassName: "loan-without-share",
        initialValue: sumLoans,
      };

      initialValueBySecClasses.push(newBySecClass);
    }

    return initialValueBySecClasses;
  };
  calculateInitialShareValue = (
    stakeholders: Stakeholder[],
    selectedStrategyIds: number[],
    currentDateTime: Date,
    correctionMappingSettings: CorrectionMappingSetting[]
  ): number => {
    let sumShares = 0;
    const initialShareValueBySecurityClass =
      this.calculateInitialShareValueBySecurityClass(
        stakeholders,
        selectedStrategyIds,
        currentDateTime,
        correctionMappingSettings
      );

    initialShareValueBySecurityClass.forEach((initialValueBySecClass) => {
      sumShares += initialValueBySecClass.initialShareValue;

      console.log(
        `INITIAL VALUE Sum of shares (security class: ${initialValueBySecClass.secClassName}): ${initialValueBySecClass.initialShareValue}`
      );
    });

    return sumShares;
  };
  calculateInitialShareValueBySecurityClass = (
    stakeholders: Stakeholder[],
    selectedStrategyIds: number[],
    currentDateTime: Date,
    correctionMappingSettings: CorrectionMappingSetting[]
  ): {
    secClassName: string;
    initialShareValue: number;
  }[] => {
    const initialShareValueBySecClasses: {
      secClassName: string;
      initialShareValue: number;
    }[] = [];
    stakeholders.forEach((st) => {
      st.shares.forEach((share) => {
        if (!selectedStrategyIds.includes(share.strategyId)) {
          return;
        }

        share.shareTransactions
          ?.filter(
            (x) => x.type === "ISSUE_SECURITY" || x.type === "CREATE_SECURITY"
          )
          ?.filter(
            (x) =>
              new Date(x.transactionDate).getTime() < currentDateTime.getTime()
          )
          ?.forEach((transaction) => {
            const correction = correctionMappingSettings.find(
              (x) =>
                x.strategyId === share.strategyId &&
                x.shareTransactionId === transaction.transactionId
            );

            let existingInitialValueBySecClass =
              initialShareValueBySecClasses.find(
                (x) => x.secClassName === transaction.holder.securityClass
              );

            if (!existingInitialValueBySecClass) {
              existingInitialValueBySecClass = {
                secClassName: transaction.holder.securityClass,
                initialShareValue: 0,
              };

              initialShareValueBySecClasses.push(
                existingInitialValueBySecClass
              );
            }

            // when a correction is configured, return the override initialShareValue value.
            if (correction) {
              console.log(
                `initialShareValue correction for transaction ${correction.shareTransactionId} to EUR ${correction.override.initialShareValue}`
              );
              existingInitialValueBySecClass.initialShareValue +=
                correction.override.initialShareValue;
              return;
            }

            existingInitialValueBySecClass.initialShareValue +=
              transaction.holder
                ? transaction.holder.capitalEquitySubscribed +
                  transaction.holder.sharePremiumSubscribed
                : 0;
          });

        share.shareTransactions
          ?.filter(
            (x) =>
              x.type === "TRANSFER_SECURITY" && x.transferDirection === "IN"
          )
          ?.filter(
            (x) =>
              new Date(x.transactionDate).getTime() < currentDateTime.getTime()
          )
          ?.forEach((transaction) => {
            let existingInitialValueBySecClass =
              initialShareValueBySecClasses.find(
                (x) => x.secClassName === transaction.holder.securityClass
              );

            if (!existingInitialValueBySecClass) {
              existingInitialValueBySecClass = {
                secClassName: transaction.holder.securityClass,
                initialShareValue: 0,
              };

              initialShareValueBySecClasses.push(
                existingInitialValueBySecClass
              );
            }

            existingInitialValueBySecClass.initialShareValue +=
              transaction.holder
                ? transaction?.holder.numberOfSecurities *
                  transaction?.holder.purchasePrice
                : 0;
          });
      });
    });

    return initialShareValueBySecClasses;
  };
  calculateInitialLoanValue = (
    stakeholders: Stakeholder[],
    selectedStrategyIds: number[],
    currentDateTime: Date,
    loanGroupingMappingSettings: LoanGroupMappingSetting[]
  ): number => {
    let sumLoans = 0;
    let sumCertificates = 0;

    stakeholders.forEach((st) => {
      // we group the loans by strategy id, because there can be multiple loans which are actually still the same loan.
      // The same loans are indicated by the same strategy id.
      // For calculating the initial value, we group the loans and take the loan with the earliest startDate.
      const initialLoans = _.chain(st.loans)
        // we need to add the group mapping to the loan, so we can decide if the loan has an initial value,
        // OR it is just a loan continuation (meaning different loan id, but still the same loan, and no new initial value)
        .map((loan: StakeholderLoan) => ({
          group:
            loanGroupingMappingSettings
              .find(
                (x) =>
                  x.strategyId === loan.strategyId &&
                  x.loanIds.includes(loan.loanId)
              )
              ?.loanIds.join("-") ?? loan.strategyId,
          ...loan,
        }))
        .groupBy((x) => [x.strategyId, x.group])
        .map((loans, strategyId) => ({
          strategyId,
          loan: _.minBy(loans, (loan) => new Date(loan.startDate)),
        }))
        .value()
        .map((x) => x.loan);

      initialLoans.forEach((loan) => {
        if (!selectedStrategyIds.includes(loan.strategyId)) {
          return;
        }
        if (new Date(loan.startDate).getTime() > currentDateTime.getTime()) {
          return;
        }

        const loanType = this.getLoanType(loan.strategyId)?.toUpperCase();

        switch (loanType) {
          case "NV":
          case "STAK": {
            sumLoans += loan.principalAmount;

            console.log(
              `INITIAL VALUE LOAN ${this.printStrategyCombinedName(
                loan.strategyId
              )} of type ${loanType}: principalAmount = ${loan.principalAmount}`
            );
            break;
          }
          case "MAATSCHAP": {
            // we need the sum of numberOfSecurities from all 'ISSUE_SECURITY' and all 'TRANSFER_SECURITY' transactions
            const loanShareTransactions = st.loanShares
              .filter((x) => x.strategyId === loan.strategyId)
              .flatMap((x) => x.shareTransactions)
              .filter(
                (x) =>
                  x.type === "ISSUE_SECURITY" || x.type === "TRANSFER_SECURITY"
              );

            const totalNumberOfSecurities = _.sum(
              loanShareTransactions.map((x) => x.holder.numberOfSecurities)
            );

            sumLoans += totalNumberOfSecurities;

            console.log(
              `INITIAL VALUE LOAN ${this.printStrategyCombinedName(
                loan.strategyId
              )} of type ${loanType}: number of shares = ${totalNumberOfSecurities}`
            );
            break;
          }
          default: {
            break;
          }
        }
      });

      st.certificates.forEach((cert) => {
        if (!selectedStrategyIds.includes(cert.strategyId)) {
          return;
        }

        if (cert.secClassName === "Z") {
          sumCertificates += cert.amount;

          console.log(
            `CERT ${this.printStrategyCombinedName(cert.strategyId)} = amount ${
              cert.amount
            }`
          );
        }
      });
    });

    console.log(
      `INITIAL VALUE Sum of loans: ${sumLoans} + sum of certificates: ${sumCertificates} = ${
        sumLoans + sumCertificates
      }`
    );

    return sumLoans + sumCertificates;
  };
  calculateGrossDistributions = (
    stakeholders: Stakeholder[],
    selectedStrategyIds: number[],
    currentDateTime: Date,
    loanGroupingMappingSettings: LoanGroupMappingSetting[],
    correctionMappingSettings: CorrectionMappingSetting[]
  ): number => {
    let grossDistributions = 0;

    console.log(`--- START OF GROSS DISTRIBUTIONS CALCULATION ---`);

    stakeholders.forEach((st) => {
      console.log(`Gross distributions calculation Stakeholder ${st.ownerId}:`);

      st.shares.forEach((share) => {
        if (!selectedStrategyIds.includes(share.strategyId)) {
          return;
        }

        share.shareTransactions
          ?.filter(
            (x) =>
              new Date(x.transactionDate).getTime() < currentDateTime.getTime()
          )
          ?.forEach((transaction) => {
            if (
              transaction.type !== "PAY_UP_UNPAID_SHARE" &&
              transaction.type !== "ADAPT_SHARE_CAPITAL" &&
              transaction.type !== "REVERT_TO_TRANSACTION"
            ) {
              if (transaction.transferDirection === "OUT") {
                if (transaction.holder.purchasePrice) {
                  grossDistributions += Math.abs(
                    transaction.holder.purchasePrice *
                      transaction.holder.numberOfSecurities
                  );
                  console.log(
                    `DISTRIBUTIONS SHARE Transaction ${
                      transaction.transactionId
                    }: ${transaction.type} -> Gross dividend: Purchase Price ${
                      transaction.holder.purchasePrice
                    } * numberOfSecurities ${
                      transaction.holder.numberOfSecurities
                    } = ${Math.abs(
                      transaction.holder.purchasePrice *
                        transaction.holder.numberOfSecurities
                    )}`
                  );
                }
              } else {
                grossDistributions += transaction.holder.grossDividend;
                console.log(
                  `DISTRIBUTIONS SHARE Transaction ${transaction.transactionId}: ${transaction.type} -> Gross dividend: ${transaction.holder.grossDividend}`
                );
              }
            } else if (transaction.type !== "REVERT_TO_TRANSACTION") {
              grossDistributions +=
                transaction.holder.capitalPayouts +
                transaction.holder.sharePremiumPayouts;
              console.log(
                `DISTRIBUTIONS SHARE Transaction ${
                  transaction.transactionId
                }: ${transaction.type} -> CapitalPayouts: ${
                  transaction.holder.capitalPayouts
                } + SharePremiumPayouts: ${
                  transaction.holder.sharePremiumPayouts
                } = ${
                  transaction.holder.capitalPayouts +
                  transaction.holder.sharePremiumPayouts
                }`
              );
            }
          });
      });

      // use the new sorted and grouped loans logic.
      selectedStrategyIds.forEach((strategyId) => {
        const loanStepDetails = this.calculateLoanStepDetails(
          strategyId,
          st,
          currentDateTime,
          loanGroupingMappingSettings,
          correctionMappingSettings
        );

        loanStepDetails.forEach((detail) => {
          if (detail.transaction === "PAYMENT") {
            grossDistributions += detail.cashFlow;
            console.log(
              `DISTRIBUTIONS LOAN ${this.printStrategyCombinedName(
                strategyId
              )} of type ${detail.transaction}: Cashflow = ${detail.cashFlow}
              )}`
            );
          }
        });
      });

      console.log(`Total gross distribution: ${grossDistributions}`);
    });

    console.log(`--- END OF GROSS DISTRIBUTIONS CALCULATION ---`);

    return grossDistributions;
  };
  getOldestTransactionDate(
    stakeholders: Stakeholder[],
    selectedStrategyIds: number[],
    currentDateTime: Date
  ): Date {
    let oldestDate = new Date();

    stakeholders?.forEach((st) => {
      st.shares?.forEach((share) => {
        if (!selectedStrategyIds.includes(share.strategyId)) {
          return;
        }

        share.shareTransactions
          ?.filter(
            (x) =>
              new Date(x.transactionDate).getTime() < currentDateTime.getTime()
          )
          ?.forEach((transaction) => {
            oldestDate =
              new Date(transaction.transactionDate) < oldestDate
                ? new Date(transaction.transactionDate)
                : oldestDate;
          });
      });

      st.loanShares?.forEach((loanShare) => {
        if (!selectedStrategyIds.includes(loanShare.strategyId)) {
          return;
        }

        loanShare.shareTransactions
          ?.filter(
            (x) =>
              new Date(x.transactionDate).getTime() < currentDateTime.getTime()
          )
          ?.forEach((transaction) => {
            oldestDate =
              new Date(transaction.transactionDate) < oldestDate
                ? new Date(transaction.transactionDate)
                : oldestDate;
          });
      });

      st.loans?.forEach((loan) => {
        if (!selectedStrategyIds.includes(loan.strategyId)) {
          return;
        }

        oldestDate =
          new Date(loan.startDate) < oldestDate
            ? new Date(loan.startDate)
            : oldestDate;
      });
    });

    return oldestDate;
  }
  calculatePaidValue = (
    stakeholders: Stakeholder[],
    strategyId: number,
    currentDateTime: Date,
    secClassName: string,
    securityTypeMappingSettings: SecurityTypeMappingSetting[]
  ): number => {
    let result = 0;

    stakeholders?.forEach((st) => {
      st.shares?.forEach((share) => {
        if (strategyId !== share.strategyId) {
          return;
        }

        share.shareTransactions
          ?.filter(
            (x) =>
              new Date(x.transactionDate).getTime() < currentDateTime.getTime()
          )
          .filter(
            (x) =>
              this.translateSecurity(
                share.strategyId,
                securityTypeMappingSettings ?? [],
                x.holder.securityClass
              ) === secClassName
          )
          .forEach((transaction) => {
            if (transaction.type === "CREATE_SECURITY") {
              if (transaction.holder.shareCapitalPaidUp) {
                result += transaction.holder.shareCapitalPaidUp;
                console.log(
                  `Calculate ${transaction.type} Paid up >> Strategy: ${share.strategyId} >> (shareCapitalPaidUp) ${transaction.holder.shareCapitalPaidUp}`
                );
              }
              if (transaction.holder.sharePremiumPaidUp) {
                result += transaction.holder.sharePremiumPaidUp;
                console.log(
                  `Calculate ${transaction.type} Paid up >> Strategy: ${share.strategyId} >> (sharePremiumPaidUp) ${transaction.holder.sharePremiumPaidUp}`
                );
              }
            } else if (
              transaction.type === "TRANSFER_SECURITY" &&
              transaction.transferDirection === "IN"
            ) {
              result +=
                transaction.holder.numberOfSecurities *
                transaction.holder.purchasePrice;
            } else if (transaction.type === "PAY_UP_UNPAID_SHARE") {
              result += transaction.holder.capitalIncreasePaid;
              result += transaction.holder.sharePremiumIncreasePaid;
            } else {
              if (transaction.holder.totalPaidUpAmount) {
                result += transaction.holder.totalPaidUpAmount;
                console.log(
                  `Calculate ${transaction.type} Paid up >> Strategy: ${share.strategyId} >> (totalPaidUpAmount) ${transaction.holder.totalPaidUpAmount}`
                );
              }
            }
          });
      });
    });

    return result;
  };
  calculateUnpaidValue = (
    stakeholders: Stakeholder[],
    strategyId: number,
    currentDateTime: Date,
    secClassName: string,
    securityTypeMappingSettings: SecurityTypeMappingSetting[]
  ): number => {
    let result = 0;

    stakeholders?.forEach((st) => {
      st.shares?.forEach((share) => {
        if (strategyId !== share.strategyId) {
          return;
        }

        share.shareTransactions
          ?.filter(
            (x) =>
              new Date(x.transactionDate).getTime() < currentDateTime.getTime()
          )
          .filter(
            (x) =>
              this.translateSecurity(
                share.strategyId,
                securityTypeMappingSettings ?? [],
                x.holder.securityClass
              ) === secClassName
          )
          .forEach((transaction) => {
            if (transaction.type === "CREATE_SECURITY") {
              if (transaction.holder.shareCapitalUnpaid) {
                result += transaction.holder.shareCapitalUnpaid;
                console.log(
                  `Calculate ${transaction.type} Unpaid >> Strategy: ${share.strategyId} >> (shareCapitalUnpaid) ${transaction.holder.shareCapitalUnpaid}`
                );
              }
              if (transaction.holder.sharePremiumUnpaid) {
                result += transaction.holder.sharePremiumUnpaid;
                console.log(
                  `Calculate ${transaction.type} Unpaid >> Strategy: ${share.strategyId} >> (sharePremiumUnpaid) ${transaction.holder.sharePremiumUnpaid}`
                );
              }
            } else if (transaction.type === "PAY_UP_UNPAID_SHARE") {
              result -= transaction.holder.capitalIncreasePaid;
              result -= transaction.holder.sharePremiumIncreasePaid;
            } else {
              if (transaction.holder.totalAmountToPay) {
                result += transaction.holder.totalAmountToPay;
                console.log(
                  `Calculate ${transaction.type} Unpaid >> Strategy: ${share.strategyId} >> (totalAmountToPay) ${transaction.holder.totalAmountToPay}`
                );
              }
            }
          });
      });
    });

    return result;
  };
  calculateCashflow(
    transaction: StakeholderShareTransaction,
    strategyId: number,
    correctionMappingSettings: CorrectionMappingSetting[]
  ): number {
    const correction = correctionMappingSettings.find(
      (x) =>
        x.strategyId === strategyId &&
        x.shareTransactionId === transaction.transactionId
    );

    // when a correction is configured, return the override cashflow value.
    if (correction) {
      console.log(
        `cashflow correction for transaction ${correction.shareTransactionId} to EUR ${correction.override.cashflow}`
      );
      return correction.override.cashflow;
    }

    // when no correction, default to normal logic.
    switch (transaction.type) {
      case "CREATE_SECURITY":
        return (
          transaction.holder.shareCapitalPaidUp +
          transaction.holder.sharePremiumPaidUp
        );
      case "ISSUE_SECURITY":
        return transaction.holder.totalPaidUpAmount;
      case "TRANSFER_SECURITY":
        return (
          transaction.holder.numberOfSecurities *
          transaction.holder.purchasePrice
        );
      case "PAY_DIVIDENDS":
        return transaction.holder.grossDividend * -1;
      case "ADAPT_SHARE_CAPITAL":
        return (
          (transaction.holder.capitalPayouts +
            transaction.holder.sharePremiumPayouts) *
          -1
        );
      case "PAY_UP_UNPAID_SHARE":
        return (
          transaction.holder.capitalIncreasePaid +
          transaction.holder.sharePremiumIncreasePaid
        );
      default:
        return 0;
    }
  }
  calculateCashflowForLoan(
    calculationStep: StakeholderLoanCalculationStepWithLoan,
    strategyId: number,
    correctionMappingSettings: CorrectionMappingSetting[],
    applyCorrection: boolean
  ): number {
    const correction = correctionMappingSettings.find(
      (x) =>
        x.strategyId === strategyId &&
        x.loanId === calculationStep.loan.loanId &&
        x.loanCalculationType === calculationStep.type
    );
    switch (calculationStep.type) {
      case "Start Loan":
        return calculationStep.grossInterest * -1;
      case "ACCRUAL":
      case "PRO_RATA":
        return 0;
      case "PAYMENT": {
        if (correction && applyCorrection) {
          console.log(
            `cashflow correction for loan ${correction.loanId}, last calculation step with type ${calculationStep.type} to EUR ${correction.override.cashflow}`
          );
          return correction.override.cashflow;
        } else {
          return calculationStep.amount + calculationStep.grossInterest;
        }
      }
    }
  }
  getShareValuePrice(
    strategyId: number,
    date: Date,
    translatedSecClassName: string
  ) {
    const prices = this.getStrategyPrices(
      strategyId,
      "SHARE",
      translatedSecClassName
    );
    const sortedPrices = prices
      ?.filter((x) => new Date(x.calculationDate).getTime() <= date.getTime())
      .sort(
        (a, b) =>
          new Date(b.calculationDate).getTime() -
          new Date(a.calculationDate).getTime()
      );
    const currentShareValuePrice = _.first(sortedPrices)?.price;
    return currentShareValuePrice;
  }
  doesStrategyApply(strategyId: number, stakeholders: Stakeholder[]) {
    let itDoes = false;

    stakeholders?.forEach((st) => {
      var certificates = st.certificates.filter(
        (x) => x.strategyId === strategyId
      );
      if (certificates?.length) {
        itDoes = true;
      }

      var commitments = st.commitments.filter(
        (x) => x.strategyId === strategyId
      );
      if (commitments?.length) {
        itDoes = true;
      }

      var loanShares = st.loanShares.filter((x) => x.strategyId === strategyId);
      if (loanShares?.length) {
        itDoes = true;
      }

      var shares = st.shares.filter((x) => x.strategyId === strategyId);
      if (shares?.length) {
        itDoes = true;
      }

      var loans = st.loans.filter((x) => x.strategyId === strategyId);
      if (loans?.length) {
        itDoes = true;
      }
    });

    return itDoes;
  }
  getShareValuePricesBySecurityClass(
    strategyId: number,
    stakeholders: Stakeholder[],
    currentDateTime: Date,
    securityClasses: SecurityTypeMappingSetting[]
  ): { [key: string]: number } {
    const shareValuePricesBySecurityClass: { [key: string]: number } = {};
    stakeholders?.forEach((st) => {
      st.shares.forEach((share) => {
        if (strategyId !== share.strategyId) {
          return;
        }

        share.shareTransactions
          ?.filter(
            (x) =>
              new Date(x.transactionDate).getTime() < currentDateTime.getTime()
          )
          ?.forEach((transaction) => {
            const currentShareValuePrice = this.getShareValuePrice(
              share.strategyId,
              currentDateTime,
              this.translateSecurity(
                share.strategyId,
                securityClasses,
                transaction.holder.securityClass
              )
            );

            if (
              !shareValuePricesBySecurityClass[transaction.holder.securityClass]
            ) {
              shareValuePricesBySecurityClass[
                transaction.holder.securityClass
              ] = currentShareValuePrice;
            }
          });
      });
    });

    return shareValuePricesBySecurityClass;
  }
  getSecurityClassNamesForStrategies(
    strategyIds: number[],
    stakeholders: Stakeholder[],
    currentDateTime: Date,
    securityClasses: SecurityTypeMappingSetting[]
  ): {
    strategyId: number;
    securityClassNames: string[];
  }[] {
    const securityClassNamesForAllStrategyIds: {
      strategyId: number;
      securityClassNames: string[];
    }[] = [];

    stakeholders?.forEach((st) => {
      st.shares.forEach((share) => {
        if (!strategyIds.includes(share.strategyId)) {
          return;
        }

        share.shareTransactions
          ?.filter(
            (x) =>
              new Date(x.transactionDate).getTime() < currentDateTime.getTime()
          )
          ?.forEach((transaction) => {
            const securityClassName = this.translateSecurity(
              share.strategyId,
              securityClasses,
              transaction.holder.securityClass
            );

            const securityClassNamesByStrategyId =
              securityClassNamesForAllStrategyIds.find(
                (x) => x.strategyId === share.strategyId
              );

            if (securityClassNamesByStrategyId) {
              if (
                !securityClassNamesByStrategyId.securityClassNames.includes(
                  securityClassName
                )
              ) {
                securityClassNamesByStrategyId.securityClassNames.push(
                  securityClassName
                );
              }
            } else {
              securityClassNamesForAllStrategyIds.push({
                strategyId: share.strategyId,
                securityClassNames: [securityClassName],
              });
            }
          });
      });

      // Loans do not have security class names, but we add a empty array for the strategies that only contains loans.
      // If we don't, the loans are not displayed in the application, and mess up the calculations.
      st.loans.forEach((loan) => {
        if (!strategyIds.includes(loan.strategyId)) {
          return;
        }

        const securityClassNamesByStrategyId =
          securityClassNamesForAllStrategyIds.find(
            (x) => x.strategyId === loan.strategyId
          );

        if (!securityClassNamesByStrategyId) {
          securityClassNamesForAllStrategyIds.push({
            strategyId: loan.strategyId,
            securityClassNames: ["loan-without-share"],
          });
        }
      });
    });

    return securityClassNamesForAllStrategyIds;
  }

  translateSecurity(
    strategyId: number,
    securityClasses: SecurityTypeMappingSetting[],
    security: string
  ): string {
    return (
      securityClasses
        .filter((x) => x.strategyIds.includes(strategyId))
        .flatMap((x) => x.mappings)
        .find((x) => x.original.trim() === security.trim())?.translated ??
      security
    );
  }
  calculateLoanStepDetails = (
    strategyId: number,
    st: Stakeholder,
    calculationDate: Date,
    loanGroupingMappingSettings: LoanGroupMappingSetting[],
    correctionMappingSettings: CorrectionMappingSetting[]
  ): LoanCalculationStepDetails[] => {
    let allSteps: StakeholderLoanCalculationStepWithLoan[] = [];
    const result: LoanCalculationStepDetails[] = [];

    _.sortBy(st.loans, (x) => new Date(x.startDate)).forEach((loan) => {
      if (strategyId !== loan.strategyId) {
        return;
      }

      if (new Date(loan.startDate).getTime() > calculationDate.getTime()) {
        return;
      }

      let calculationsWithLoan: StakeholderLoanCalculationStepWithLoan[] = [];

      const loanGroupingSettingsExistForStrategy =
        loanGroupingMappingSettings.filter((x) => x.strategyId === strategyId);

      let startLoanExists = false;
      let includeLoan = false;

      if (loanGroupingSettingsExistForStrategy.length > 0) {
        // if there is no grouping in the config, create a grouping with only this loan id.
        const groupedLoanIds =
          loanGroupingSettingsExistForStrategy.find((x) =>
            x?.loanIds.includes(loan.loanId)
          )?.loanIds ?? [];

        includeLoan = groupedLoanIds.length > 0;
        startLoanExists =
          allSteps.filter(
            (x) =>
              x.type === "Start Loan" && groupedLoanIds.includes(x.loan.loanId)
          ).length > 0;
      } else {
        // SCENARIO 1:
        // there is a possibility to have multiple start loans in a strategy, with different lender ids, with the same owner id and same loan ids.
        // e.g. Strategy 54 (Vanhaverbeke - Raamwinkel) for OwnerId 458 (Zwynnelande) has 2 lender ids and same loan id.
        // --> In this case, there should be 2 'Start Loan's.
        // SCENARIO 2:
        // there is also a possibility to have multiple starts loans in a strategy, with different lender is, with the same owner id and different loan ids.
        // e.g. Strategy 1009 (BrantsandPatents) for OwnerId 458 (Zwynnelande) has 2 lender ids and different loan id.
        // --> In this case, there should only be 1 Start Loan.
        const groupedLoanIds = allSteps.map((x) => x.loan.loanId);

        // this should be false for SCENARIO 1 -> because the loan ids are the same, we'll create 2 Start Loan's.
        // this should be false for SCENARIO 2 -> because the loan ids are different, we'll group the loan.
        if (!startLoanExists) {
          startLoanExists =
            allSteps.filter(
              (x) =>
                x.type === "Start Loan" && // check for the type Start Loan
                groupedLoanIds.includes(x.loan.loanId) && // we check if the current loan is in the current group (but there is no configuration, so we always group).
                x.loan.loanId !== loan.loanId // and the start loan id should be different from the current loan id
            ).length > 0;
        }

        includeLoan = true;
      }

      if (!startLoanExists && includeLoan) {
        const initialLoanState: StakeholderLoanCalculationStepWithLoan = {
          amount: 0,
          date: loan.startDate,
          grossInterest: loan.principalAmount,
          netInterest: loan.principalAmount,
          outstandingNetInterest: 0,
          outstandingPrincipalAmount: loan.principalAmount,
          totalGrossInterest: 0,
          totalNetInterest: 0,
          totalOutstandingAmount: loan.principalAmount,
          totalWithHoldingTax: null,
          type: "Start Loan",
          withHoldingTax: null,
          loan: loan,
        };

        console.log(
          `Start loan ${loan.startDate} for lender: ${loan.lenderId} `
        );

        calculationsWithLoan.push(initialLoanState);
      }

      calculationsWithLoan.push(
        ...loan.loanCalculations
          .reduce((acc, calc) => {
            const calculationStepWithLoan = calc.calculationSteps.map(
              (calculation) => ({
                ...calculation,
                loan: loan,
              })
            );
            return acc.concat(calculationStepWithLoan);
          }, [] as StakeholderLoanCalculationStepWithLoan[])
          ?.filter(
            (x) => new Date(x.date).getTime() < calculationDate.getTime()
          )
      );

      allSteps.push(...calculationsWithLoan);
    });

    const sortedSteps = [...allSteps].sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );

    sortedSteps.forEach((step, index) => {
      let grossAmountTransaction = 0;
      let netInterest = 0;
      let totalOutstandingAmountPost = 0;

      // only do this when it is the last payment in the loan.
      // this code below filters on all next PAYMENTS. When there are no other payments with a higher index, we know we have the last PAYMENT step.
      let applyCorrection =
        sortedSteps.filter((s, i) => s.type === "PAYMENT" && i >= index)
          .length > 0;
      let cashFlow = this.calculateCashflowForLoan(
        step,
        strategyId,
        correctionMappingSettings,
        applyCorrection
      );

      switch (step.type) {
        case "Start Loan":
          grossAmountTransaction = step.grossInterest;
          netInterest = step.netInterest;
          totalOutstandingAmountPost = step.totalOutstandingAmount;
          break;
        case "ACCRUAL":
        case "PRO_RATA":
          grossAmountTransaction = step.grossInterest;
          netInterest = step.netInterest;
          totalOutstandingAmountPost = step.totalOutstandingAmount;
          break;
        case "PAYMENT":
          console.log("CalcLoanStepDetail: PAYMENT");
          grossAmountTransaction = (step.amount + step.grossInterest) * -1;
          // according to US10172 in DevOps, the netAmountTransaction = grossAmountTransaction - RV (euro).
          // according to US10657 in DevOps, the RV should be negative if the grossAmountTransaction is negative.
          netInterest =
            grossAmountTransaction -
            (step.totalWithHoldingTax !== null
              ? +step.withHoldingTax * (grossAmountTransaction < 0 ? -1 : 1)
              : 0);

          totalOutstandingAmountPost = step.totalOutstandingAmount;
          break;
      }

      result.push({
        transaction: step.type,
        date: new Date(step.date),
        interest: step.loan.interestRate,
        totalOutstandingAmountPre:
          index > 0 ? sortedSteps[index - 1].totalOutstandingAmount : null,
        totalOutstandingAmountPost: totalOutstandingAmountPost,
        grossAmountTransaction: grossAmountTransaction,
        rv: step.loan.witholdingTax !== null ? +step.loan.witholdingTax : null,
        rvEuro: step.withHoldingTax !== null ? +step.withHoldingTax : null,
        netAmountTransaction: netInterest,
        cashFlow: cashFlow,
        loanId: step.loan.loanId,
      });
    });

    return result;
  };
}

export default StrategyCalculator;
