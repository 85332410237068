import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Flex,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  resetActualValueState,
  resetSelectedEntityIds,
  resetSelectedStrategyIds,
  setSelectedDate,
  setSelectedEntityIds,
  setSelectedStrategyids,
} from "../../../redux/slices/dataSlice";
import {
  fetchCorrectionMappingSettings,
  fetchEntitiesByEmail,
  fetchLoanGroupingMappingSettings,
  fetchSecurityTypeMappingSettings,
  fetchStakeholders,
  fetchStrategyMappingSettings,
  fetchTranslationMappingSettings,
  fetchusersByEmail,
} from "../../../redux/slices/thunks";
import { Entity, StrategyMapping, } from "../../../redux/slices/types";
import DatePickerSelect from "../../calendar/DatePickers";
import { IoFilter, IoRefresh } from 'react-icons/io5';

type Props = {
  onFilterCountChange: (filterCount: number) => void;
}

const Filter = ({ onFilterCountChange }: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  let { users, entitiesbyEmail } = useSelector(
    (state: RootState) =>  state.data
  );
  const selectedEntityIds = useSelector(
    (state: RootState) => state.data.selectedEntityIds
  );
  const strategyMappingSettings: StrategyMapping = useSelector(
    (state: RootState) => state.data.strategyMappingSettings
  );
  const selectedStrategyIds: number[] = useSelector(
    (state: RootState) => state.data.selectedStrategyIds
  );
  const email = useSelector(
    (state: RootState) => state.data.selectedEmail ?? ""
  );
  const userStrategies = useSelector((state: RootState) =>
    state.data.stakeholders.flatMap(stakeholder =>
      [
        ...stakeholder.commitments.map(commitment => commitment.strategyId),
        ...stakeholder.shares.map(share => share.strategyId),
        ...stakeholder.loanShares.map(loanShare => loanShare.strategyId),
        ...stakeholder.loans.map(loans => loans.strategyId),
        ...stakeholder.certificates.map(certificate => certificate.strategyId),
      ]
    )
  );

  const [selectedRole, setSelectedRole] = useState<string>(
    () => sessionStorage.getItem("selectedRole") || ""
  );
  const mappedStrategies = useMemo(() => {
    return strategyMappingSettings.values
      .map((strategyMap) => ({
        strategyTitle: strategyMap.strategy,
        strategies: strategyMap.combined
          .filter((rCombo) =>
            // Only include strategies that match userStrategies by default
            rCombo.values.some((value) => userStrategies.includes(value.entityId))
          )
          .map((rCombo) => ({
            name: rCombo.name,
            entityIds: rCombo.values.map((val) => val.entityId),
            isActive: rCombo.values
              .map((value) => value.entityId)
              .some((entityId) => selectedStrategyIds.includes(entityId)),
          })),
      }))
      .filter((strategyMap) => strategyMap.strategies.length > 0); // Remove empty strategy groups
  }, [strategyMappingSettings, userStrategies, selectedStrategyIds]);
  
  
  
  const selectedStrategies: string[] = useMemo(() => {
    return mappedStrategies
      .flatMap(s => s.strategies
        .filter(ss => ss.isActive)
        .map(ss => ss.name)
      )
  }, [mappedStrategies]);

  useEffect(() => {
    // Check if users array is not null and has length greater than 0
    if (users && users.length > 0) {
      // Check if user has both Investor and Advisor roles
      const hasInvestorRole = users.some((user) => user.role === "Investor");
      const hasAdvisorRole = users.some((user) => user.role === "Advisor");

      if (hasInvestorRole && hasAdvisorRole) {
        setSelectedRole("Investor");
        console.log('rolwissel - 111')
      } else if (hasInvestorRole) {
        setSelectedRole("Investor");
        console.log('rolwissel - 222')
      } else if (hasAdvisorRole) {
        setSelectedRole("Advisor");
        console.log('rolwissel - 333')
      }
    }
  }, [users, email]);

  useEffect(() => {
    dispatch(fetchStrategyMappingSettings());
    dispatch(fetchTranslationMappingSettings());
    dispatch(fetchSecurityTypeMappingSettings());
    dispatch(fetchLoanGroupingMappingSettings());
    dispatch(fetchCorrectionMappingSettings());
    if (email) {
      dispatch(fetchusersByEmail(email));
    }
  }, [email, dispatch]);

  useEffect(() => {
    if (selectedRole) {
      dispatch(
        fetchEntitiesByEmail({
          userIds: users.map((x) => x.id),
          role: selectedRole,
        })
      );
    }
  }, [selectedRole, email, dispatch, users]);

  useEffect(() => {
    if (selectedEntityIds?.length > 0) {
      dispatch(fetchStakeholders({ ownerIds: selectedEntityIds }));
    }
  }, [selectedEntityIds, dispatch]);

  const getStoredEntities = useCallback((): number[] => {
    const storedEntities = sessionStorage.getItem("selectedEntities");
    if (storedEntities) {
      return JSON.parse(storedEntities) as number[];
    }
    return [];
  }, []);

  useEffect(() => {
    const storedRole = sessionStorage.getItem("selectedRole");
    if (storedRole) {
      setSelectedRole(storedRole);
      dispatch(
        fetchEntitiesByEmail({
          userIds: users.map((x) => x.id),
          role: storedRole,
        })
      );
    }

    const storedEntities = getStoredEntities();
    if (storedEntities) {
      dispatch(setSelectedEntityIds(storedEntities));
    }
  }, [dispatch, getStoredEntities, users]);

  useEffect(() => {
    if (entitiesbyEmail.length > 0) {
      // Sort entities alphabetically by entity name
      const sortedEntities = entitiesbyEmail
        .slice()
        .sort((a, b) => a.entityName.localeCompare(b.entityName));

      const entityIdsInStorage: number[] = getStoredEntities();

      const selectedEntities =
        sortedEntities?.filter((x) =>
          entityIdsInStorage.includes(Number(x.entityId))
        ) ?? [];

      setNewEntitiesToSelect(
        selectedEntities.length ? selectedEntities : sortedEntities
      );
    }

    function setNewEntitiesToSelect(sortedEntities: Entity[]) {
      if (selectedRole === "Investor") {
        // Get all entityId from the sorted list
        const entityIdsToSelect = sortedEntities.map((entity) =>
          Number(entity.entityId)
        );

        dispatch(setSelectedEntityIds(entityIdsToSelect));
        sessionStorage.setItem(
          "selectedEntities",
          JSON.stringify(entityIdsToSelect)
        );
        dispatch(fetchStakeholders({ ownerIds: entityIdsToSelect }));
      } else if (selectedRole === "Advisor") {
        // Get the first entityId from the sorted list
        const defaultEntityId =
          sortedEntities.length > 0 ? sortedEntities[0].entityId : null;

        if (defaultEntityId !== null) {
          const entityIdToSelect = Number(defaultEntityId);
          dispatch(setSelectedEntityIds([entityIdToSelect]));
          sessionStorage.setItem(
            "selectedEntities",
            JSON.stringify([entityIdToSelect])
          );
          dispatch(fetchStakeholders({ ownerIds: [entityIdToSelect] }));
        }
      }
    }
  }, [selectedRole, entitiesbyEmail, dispatch, getStoredEntities]);

  const handleRoleChange = (role: string | string[]) => {
    setSelectedRole(role as string);
    sessionStorage.setItem("selectedRole", role as string);
    dispatch(setSelectedEntityIds([]));
    sessionStorage.removeItem("selectedEntities");
  };

  const handleStrategyChange = (strategy: string | string[]) => {
         dispatch(setSelectedStrategyids(mappedStrategies.flatMap(ms => ms.strategies
      .filter(s => strategy.includes(s.name))
      .flatMap(s => s.entityIds)
    )))
  }

  const handleEntityChange = (entities: string | string[]) => {
    const selectedEntities = Array.isArray(entities)
      ? entities.map((id) => Number(id))
      : [Number(entities)];
    if (selectedEntities.length === 0) {
      return;
    }
    dispatch(setSelectedEntityIds(selectedEntities));
    sessionStorage.setItem("selectedEntities", JSON.stringify(selectedEntities));
  };

  const getEntityNames = (ids: number[]) => {
    return ids.map((id) => {
      const entity = entitiesbyEmail.find((e) => +e.entityId === id);

      return selectedRole === "Investor" && entity?.entityType === "PERSON"
        ? `${entity.name}, ${entity.firstName}`
        : entity?.entityName ?? "";
    });
  };

  // Sort entities alphabetically by entity name
  const sortedEntitiesbyEmail = entitiesbyEmail
    .slice()
    .sort((a, b) => a.entityName.localeCompare(b.entityName));
  const entityNames = getEntityNames(selectedEntityIds);
  const maxNameLength = 10;

  const truncateName = (name: string) => {
    return name.length > maxNameLength
      ? name.slice(0, maxNameLength) + "..."
      : name;
  };

  const truncatedEntityNames = entityNames.map(truncateName);
  const displayText =
    selectedEntityIds?.length === 0
      ? "Entities"
      : selectedEntityIds?.length === 1
        ? truncatedEntityNames[0]
        : "Consolidated";


  const amountOfSelectedStrategies = useMemo(() => {
    return selectedStrategies.filter(s => s !== 'Commitment').length
  }, [selectedStrategies]);

  const totalAmountOfSelectableStrategies = useMemo(() => {
    return mappedStrategies
      .flatMap(s =>
        s.strategies
          .filter(s => s.name !== 'Commitment')
          .map(ss => ss.name)
      )
      .length
  }, [mappedStrategies]);

  const displaySelectedStrategy = useMemo(() => {

    if (amountOfSelectedStrategies === 0) {
      return 'No strategy'
    }

    if (amountOfSelectedStrategies === totalAmountOfSelectableStrategies) {
      return "All strategies"
    }

    if (amountOfSelectedStrategies === 1) {
      return "1 strategy"
    }

    return `${amountOfSelectedStrategies} strategies`

  }, [amountOfSelectedStrategies, totalAmountOfSelectableStrategies])

  const uniqueEntities = useMemo(() => {
    const seenEntityNames = new Set();
    return sortedEntitiesbyEmail.filter((entity: any) => {
      if (seenEntityNames.has(entity.entityName)) {
        return false;
      }
      seenEntityNames.add(entity.entityName);
      return true;
    });
  }, [sortedEntitiesbyEmail]);

  useEffect(() => {
    const shouldShowFilterCount = amountOfSelectedStrategies !== totalAmountOfSelectableStrategies
    onFilterCountChange(shouldShowFilterCount ? amountOfSelectedStrategies : -1)
  }, [amountOfSelectedStrategies, totalAmountOfSelectableStrategies, onFilterCountChange]);
// Toggles between Select All and Deselect All
const handleToggleSelectAll = () => {
  const allSelected = selectedStrategies.length === mappedStrategies.flatMap(strategyMap => strategyMap.strategies).length;
  if(allSelected)
    {
      handleStrategyChange("");
     
    
    }else
    {
      handleStrategyChange(
        mappedStrategies.flatMap(ms => ms.strategies)
        .flatMap(s => s.name));
    }
};

// Function to toggle all strategies within a specific group
const handleGroupTitleClick = (strategyTitle: string) => {
  // Get all strategy names from this group
  const groupStrategies = mappedStrategies
    .find(group => group.strategyTitle === strategyTitle)
    ?.strategies
    .filter(s => s.name !== 'Commitment')
    .map(s => s.name) || [];
  
  // Check if all strategies in this group are currently active
  const allGroupStrategiesActive = groupStrategies.every(strategyName => 
    selectedStrategies.includes(strategyName)
  );

  if (allGroupStrategiesActive) {
    // If all are active, deselect all in this group by removing them from current selection
    const strategiesWithoutGroup = selectedStrategies.filter(
      name => !groupStrategies.includes(name)
    );
    handleStrategyChange(strategiesWithoutGroup);
  } else {
    // If not all active, select all in this group by adding them to current selection
    const updatedStrategies = [
      ...selectedStrategies.filter(name => !groupStrategies.includes(name)), // Keep other selections
      ...groupStrategies // Add all from this group
    ];
    handleStrategyChange(updatedStrategies);
  }
};

  return (
    <>
      <Box>
        <DatePickerSelect/>
      </Box>

      <Menu closeOnSelect={false} isLazy>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon/>}
          padding={4}
          _hover={{ bg: 'gray.100' }}
          _active={{ bg: 'gray.200' }}
          transition="all 0.2s"
        >
          {displaySelectedStrategy}
        </MenuButton>
        <MenuList
          maxHeight="60vh"
          overflow="auto"
        >
          <MenuItem 
            onClick={handleToggleSelectAll}
            mb={2}
            borderRadius="md"
            bg={selectedStrategies.length === mappedStrategies.flatMap(strategyMap => strategyMap.strategies).length ? 'gray.100' : 'transparent'}
            _hover={{ 
              bg: 'gray.200',
              transition: 'all 0.2s'
            }}
            _active={{ bg: 'gray.200' }}
          >
            {selectedStrategies.length === mappedStrategies.flatMap(strategyMap => strategyMap.strategies).length ? "Deselect All" : "Select All"}
          </MenuItem>
          
          {mappedStrategies.map((strategyMap) => {
            // Get all strategy names from this group (excluding 'Commitment')
            const groupStrategies = strategyMap.strategies
              .filter(s => s.name !== 'Commitment')
              .map(s => s.name);
              
            // Check if all strategies in this group are active
            const allGroupStrategiesActive = groupStrategies.every(name => 
              selectedStrategies.includes(name)
            );
            
            return (
              <Box key={strategyMap.strategyTitle}>
                {/* Clickable group title */}
                <MenuItem 
                  fontWeight="bold" 
                  onClick={() => handleGroupTitleClick(strategyMap.strategyTitle)}
                  bg={allGroupStrategiesActive ? 'gray.100' : 'transparent'}
                  borderRadius="md"
                  mb={1}
                  role="group"
                  className="group-title-item"
                  _hover={{ 
                    bg: 'gray.200',
                    cursor: 'pointer'
                  }}
                  _active={{ bg: 'gray.200' }}
                >
                  <Flex width="100%" align="center">
                    <Box 
                      borderLeft="3px solid" 
                      borderColor="brand.500"
                      pl={2}
                      transition="all 0.2s"
                      mr={2}
                      height="1.2em"
                    />
                    <Box 
                      className="group-title"
                    >{strategyMap.strategyTitle}</Box>
                  </Flex>
                </MenuItem>
                
                {/* Strategy options */}
                <Box pl={4}>
                  <MenuOptionGroup
                    type="checkbox"
                    value={selectedStrategies}
                    onChange={handleStrategyChange}
                    textAlign="left"
                  >
                    {strategyMap.strategies.map(strategy => {
                      if (strategy.name === 'Commitment') {
                        return null;
                      }
                      const isSelected = selectedStrategies.includes(strategy.name);
                      return (
                        <MenuItemOption 
                          key={strategy.name} 
                          value={strategy.name}
                          borderRadius="md"
                          bg={isSelected ? 'gray.100' : 'transparent'}
                          _hover={{ 
                            bg: 'gray.200',
                            transition: 'all 0.2s'
                          }}
                          _active={{ bg: 'gray.200' }}
                          _selected={{ bg: 'gray.200' }}
                        >
                          {strategy.name}
                        </MenuItemOption>
                      );
                    })}
                  </MenuOptionGroup>
                </Box>
              </Box>
            );
          })}
        </MenuList>
      </Menu>

      <Menu closeOnSelect={true}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon/>}
          padding={4}
          _hover={{ bg: 'gray.100' }}
          _active={{ bg: 'gray.200' }}
          transition="all 0.2s"
        >
          {selectedRole ? selectedRole : "Role"}
        </MenuButton>
        <MenuList>
          <MenuOptionGroup
            value={selectedRole}
            onChange={handleRoleChange}
            type="radio"
          >
            {Array.from(
              new Set(users.map((stakeholder: any) => stakeholder.role))
            ).map((role) => (
              <MenuItemOption 
                key={role} 
                value={role}
                borderRadius="md"
                mb={1}
                _hover={{ 
                  bg: 'gray.200',
                  transition: 'all 0.2s'
                }}
                _active={{ bg: 'gray.200' }}
                _selected={{ bg: 'gray.100' }}
              >
                {role}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>

      <Menu closeOnSelect={false} isLazy={true}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon/>}
          padding={4}
          _hover={{ bg: 'gray.100' }}
          _active={{ bg: 'gray.200' }}
          transition="all 0.2s"
        >
          {displayText}
        </MenuButton>
        <MenuList
          maxHeight="60vh"
          overflow="auto"
        >
          {selectedRole === "Investor" ? (
            <MenuOptionGroup
              onChange={(values) => handleEntityChange(values)}
              value={selectedEntityIds.map((x) => x.toString())}
              type="checkbox"
            >
              {sortedEntitiesbyEmail.map((entity) => (
                <MenuItemOption
                  key={entity.entityId}
                  value={entity.entityId.toString()}
                  borderRadius="md"
                  mb={1}
                  _hover={{ 
                    bg: 'gray.200',
                    transition: 'all 0.2s'
                  }}
                  _active={{ bg: 'gray.200' }}
                  _selected={{ bg: 'gray.100' }}
                >
                  {entity.entityType === "PERSON"
                    ? `${entity.name}, ${entity.firstName}`
                    : entity.entityName}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          ) : (
            <MenuOptionGroup
              onChange={handleEntityChange}
              value={selectedEntityIds[0]?.toString()}
              type="radio"
            >
              {uniqueEntities.map((entity: Entity) => (
                <MenuItemOption
                  key={entity.entityId}
                  value={entity.entityId.toString()}
                  borderRadius="md"
                  mb={1}
                  _hover={{ 
                    bg: 'gray.200',
                    transition: 'all 0.2s'
                  }}
                  _active={{ bg: 'gray.200' }}
                  _selected={{ bg: 'gray.100' }}
                >
                  <Flex width="100%" align="center">
                    <Box 
                      borderLeft="3px solid" 
                      borderColor="brand.500"
                      pl={2}
                      transition="all 0.2s"
                      mr={2}
                      height="1.2em"
                      opacity={selectedEntityIds[0]?.toString() === entity.entityId.toString() ? 1 : 0}
                    />
                    {entity.entityName}
                  </Flex>
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          )}
        </MenuList>
      </Menu>
    </>
  );
}

export default function FilterBar() {
  const dispatch = useDispatch();

  const [filterBadgeNumber, setFilterBadgeNumber] = useState(0);

  const handleResetFilters = useCallback(() => {
    dispatch(resetSelectedStrategyIds())
    dispatch(resetSelectedEntityIds())
    dispatch(resetActualValueState())
    dispatch(setSelectedDate(new Date().toDateString()));
  }, [])

  return (
    <>
      <Box
        display={{ base: 'none', sm: 'none', md: 'none', xl: 'flex' }}
        alignItems='center'
        flexWrap="wrap"
        ml='auto'
      >
        <IconButton aria-label="refresh filters" onClick={handleResetFilters}>
          <Icon
            as={IoRefresh}
            w='16px'
            h='16px'
          />
        </IconButton>
        <Filter onFilterCountChange={setFilterBadgeNumber}/>
      </Box>

      <Box
        display={{ base: 'flex', sm: 'flex', md: 'flex', xl: 'none' }}
        alignItems='center'
        flexWrap="wrap"
        ml='auto'
      >
        <Menu>
          <MenuButton p={0} mx={6}>
            <Avatar
              icon={<IoFilter size={16}/>}
              size="sm"
              w="40px"
              h="40px"
            >
              <AvatarBadge
                bg='green.500'
                color='white'
                boxSize="20px"
                hidden={filterBadgeNumber === -1}
              >
                {filterBadgeNumber}
              </AvatarBadge>
            </Avatar>
          </MenuButton>

          <MenuList>
            <Box display='flex' flexDirection='column' alignItems='end'>
              <Filter onFilterCountChange={setFilterBadgeNumber}/>

              <MenuItem onClick={handleResetFilters}>
                <Box display='flex' alignItems="center" justifyContent='center' flexDirection='row' gap={4} w="100%">
                  <Icon
                    as={IoRefresh}
                    w='16px'
                    h='16px'
                  />
                  <span>clear</span>
                </Box>
              </MenuItem>

            </Box>
          </MenuList>
        </Menu>
      </Box>
    </>
  );
}
