import {
  Flex,
  Spinner,
  Text,
  Button,
  useBreakpointValue,
  useColorModeValue,
  ButtonGroup,
  HStack,
  Tooltip,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useCallback, useEffect, useMemo, useState } from "react";
import LineChart from "components/charts/LineChart";
import StrategyCalculator from "utils/StrategyCalculator";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { useFontSize } from "variables/charts";
import { globalStyles } from "theme/styles";
import React from "react";

// Memoized navigation component to prevent rerenders
const ChartNavigation = React.memo(
  ({
    currentPage,
    maxPage,
    dataReady,
    onPrevious,
    onNext,
  }: {
    currentPage: number;
    maxPage: number;
    dataReady: boolean;
    onPrevious: () => void;
    onNext: () => void;
  }) => {
    return (
      <Flex mt="10px" mb="10px" justify="center" w="100%">
        <Tooltip label="Show older quarters">
          <Button
            onClick={onPrevious}
            isDisabled={currentPage === 0 || !dataReady}
            size="sm"
            leftIcon={<span>←</span>}
            mr={2}
          >
            Previous
          </Button>
        </Tooltip>
        <Tooltip label="Show newer quarters">
          <Button
            onClick={onNext}
            isDisabled={currentPage >= maxPage || !dataReady}
            size="sm"
            rightIcon={<span>→</span>}
            ml={2}
          >
            Next
          </Button>
        </Tooltip>
      </Flex>
    );
  }
);

export default function HistoricalValue(props: {
  showHistoricalData: boolean;
}) {
  const { showHistoricalData, ...rest } = props;

  const { loading } = useSelector((state: RootState) => state.data);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const stakeholders = useSelector(
    (state: RootState) => state.data.stakeholders
  );
  const strategyMappingSettings = useSelector(
    (state: RootState) => state.data.strategyMappingSettings
  );
  const selectedStrategyIds = useSelector(
    (state: RootState) => state.data.selectedStrategyIds
  );
  const selectedDate = useSelector(
    (state: RootState) => state.data.selectedDate
  );
  const securityTypeMappingSettings = useSelector(
    (state: RootState) => state.data.securityTypeMappingSettings
  );

  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR",
      }),
    []
  );

  // Initialize chart data as null until properly loaded
  const [lineChartData, setLineChartData] = useState<any>(null);
  const [minValue, setMinValue] = useState<number | null>(null);
  const [maxValue, setMaxValue] = useState<number | null>(null);

  // Add a state to track data loading
  const [dataReady, setDataReady] = useState<boolean>(false);

  // Default is 8 quarters, but can be changed with the tristate toggle
  const [quartersToShow, setQuartersToShow] = useState<number>(8);

  const strategyCalculator = useMemo(
    () => new StrategyCalculator(strategyMappingSettings),
    [strategyMappingSettings]
  );

  // Mobile detection for UI adjustments
  const isMobile = useBreakpointValue({ sm: true, md: false, xl: false });

  // Set quarters to show based on device
  useEffect(() => {
    if (isMobile) {
      setQuartersToShow(4); // Default to 4 quarters on mobile
    }
  }, [isMobile]);

  // Updated getAllQuarters to use selectedDate
  const allQuarters = useMemo<string[]>(() => {
    const quarters = [];
    const today = selectedDate ? new Date(selectedDate) : new Date(); // Now using selectedDate instead of today

    const getQuarter = (month: number) => Math.floor(month / 3) + 1;

    // Check if the quarter is finished based on the selected date
    const isQuarterFinished = (quarter: number, year: number): boolean => {
      const quarterEndDates = [
        new Date(year, 2, 31), // End of Q1: March 31
        new Date(year, 5, 30), // End of Q2: June 30
        new Date(year, 8, 30), // End of Q3: September 30
        new Date(year, 11, 31), // End of Q4: December 31
      ];
      return today > quarterEndDates[quarter - 1]; // Return true if selectedDate is after the quarter-end date
    };

    for (let i = 0; i < 20; i++) {
      // Increased from 12 to 20 to allow for more historical quarters
      const monthsAgo = today.getMonth() - i * 3;
      let year = today.getFullYear();
      let month = monthsAgo;

      while (month < 0) {
        month += 12;
        year -= 1;
      }

      while (month > 11) {
        month -= 12;
        year += 1;
      }

      const quarter = getQuarter(month);

      // Only push quarters that are finished
      if (isQuarterFinished(quarter, year)) {
        quarters.push(`Q${quarter} ${year}`);
      }
    }

    return quarters.reverse(); // Reverse to display the oldest first
  }, [selectedDate]);

  // Initial page calculated to show the most recent quarters
  const [currentPage, setCurrentPage] = useState<number>(0);

  // Set the initial page to show the most recent quarters when quarters to show changes
  useEffect(() => {
    if (allQuarters.length > 0) {
      const maxPage = Math.max(0, allQuarters.length - quartersToShow);
      setCurrentPage(maxPage);
    }
  }, [allQuarters, quartersToShow]);

  // Function to organize quarters by year for display
  const organizeQuartersByYear = (
    quarters: string[]
  ): (string | number)[][] => {
    const result: (string | number)[][] = [];
    let lastYear = "";

    quarters.forEach((quarter, index) => {
      const [q, year] = quarter.split(" ");

      if (isMobile) {
        // Keep the Q prefix for all quarters even on mobile
        // Only add the year as a separate label if it's different from the last one
        if (year !== lastYear) {
          result.push([q, year]);
          lastYear = year;
        } else {
          result.push([q, ""]); // No year for subsequent quarters in the same year
        }
      } else {
        // Desktop view - regular display
        if (year !== lastYear) {
          result.push([q, year]);
          lastYear = year;
        } else {
          result.push([q, ""]);
        }
      }
    });

    return result;
  };

  const getEndDateForQuarter = (input: string): Date => {
    const quarter = input.split(" ")[0];
    const year = input.split(" ")[1];

    switch (quarter) {
      case "Q1":
        return new Date(+year, 3, 1);
      case "Q2":
        return new Date(+year, 6, 1);
      case "Q3":
        return new Date(+year, 9, 1);
      case "Q4":
        return new Date(+year + 1, 0, 1);
      default:
        return new Date();
    }
  };

  useEffect(() => {
    if (stakeholders && strategyMappingSettings) {
      try {
        // Set dataReady to false while processing
        setDataReady(false);

        // Calculate the start and end indexes based on currentPage and quartersToShow
        const startIndex = Math.min(
          currentPage,
          Math.max(0, allQuarters.length - quartersToShow)
        );
        const endIndex = Math.min(
          startIndex + quartersToShow,
          allQuarters.length
        );

        const displayedQuarters = allQuarters.slice(startIndex, endIndex);

        // Initialize default data structure in case there's no data
        const defaultData = [
          ["Period", "Actual Value", { role: "annotation" }],
          ["No Data", 0, currencyFormatter.format(0)],
        ];

        // Safety check to prevent errors
        if (!displayedQuarters.length) {
          setLineChartData(defaultData);
          setMinValue(0);
          setMaxValue(100);
          setDataReady(true);
          return;
        }

        const dates = displayedQuarters.map((range) =>
          getEndDateForQuarter(range)
        );
        const actualValues = dates.map((endDate) => {
          const actualValue = strategyCalculator.calculateActualValue(
            stakeholders,
            selectedStrategyIds,
            endDate,
            securityTypeMappingSettings.securityTypes ?? []
          );
          return actualValue;
        });

        // Calculate max value for better scaling with a 10% buffer
        let maxVal = Math.max(...actualValues);

        // Add buffer to prevent small changes from appearing as big spikes
        const buffer = maxVal * 0.1; // 10% buffer
        maxVal = maxVal + buffer;

        // Always set min value to 0
        setMinValue(0);
        setMaxValue(maxVal || 100); // Ensure maxValue is never 0 or undefined

        const data: (string | number | object)[][] = [];
        data.push(["Period", "Actual Value", { role: "annotation" }]);

        const organizedQuarters = organizeQuartersByYear(displayedQuarters);
        organizedQuarters.forEach((quarterInfo, index) => {
          const value = actualValues[index];
          data.push([
            quarterInfo[0] + (quarterInfo[1] ? "\n" + quarterInfo[1] : ""), // Quarter with year on new line if present
            value,
            currencyFormatter.format(value),
          ]);
        });

        // Validate data format before setting it
        if (data.length > 1) {
          // Ensure all data rows have the same number of columns as the header
          const headerLength = data[0].length;
          const validData = data.every((row) => row.length === headerLength);

          if (!validData) {
            console.error(
              "Invalid chart data format: rows have inconsistent column count",
              data
            );
            setLineChartData(defaultData);
          } else {
            setLineChartData(data);
          }

          // Mark data as ready after successful processing
          setDataReady(true);
        } else {
          setLineChartData(defaultData);
          setDataReady(true);
        }
      } catch (error) {
        console.error("Error preparing chart data:", error);
        // Set default data when an error occurs
        setLineChartData([
          ["Period", "Actual Value", { role: "annotation" }],
          ["No Data", 0, currencyFormatter.format(0)],
        ]);
        setMinValue(0);
        setMaxValue(100);
        setDataReady(true);
      }
    } else {
      // Set default data when stakeholders or strategyMappingSettings are not available
      setLineChartData([
        ["Period", "Actual Value", { role: "annotation" }],
        ["No Data", 0, currencyFormatter.format(0)],
      ]);
      setMinValue(0);
      setMaxValue(100);
      setDataReady(true);
    }
  }, [
    stakeholders,
    selectedStrategyIds,
    strategyMappingSettings,
    strategyCalculator,
    currencyFormatter,
    currentPage,
    allQuarters,
    quartersToShow,
  ]);

  // Debounce page changes to prevent excessive rerenders
  const handlePrevious = useCallback(() => {
    if (currentPage > 0) {
      // Disable data ready state before changing page to prevent double rendering
      setDataReady(false);
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage]);

  const handleNext = useCallback(() => {
    const maxPage = Math.max(0, allQuarters.length - quartersToShow);
    if (currentPage < maxPage) {
      // Disable data ready state before changing page to prevent double rendering
      setDataReady(false);
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage, allQuarters.length, quartersToShow]);

  const handleQuarterToggle = useCallback(
    (count: number) => {
      if (count !== quartersToShow) {
        // Disable data ready state before changing quartersToShow to prevent double rendering
        setDataReady(false);
        setQuartersToShow(count);
      }
    },
    [quartersToShow]
  );

  const fontSize = useBreakpointValue({ sm: 10, md: 11, xl: 13 });
  // Add responsive chart area configuration
  const chartAreaConfig = useBreakpointValue({
    sm: { left: 65, top: 40, right: 20, bottom: 60 },
    md: { left: 75, top: 40, right: 30, bottom: 60 },
    xl: { left: 85, top: 40, right: 40, bottom: 60 },
  });

  const LineChartOptionsConsumption = useMemo(
    () => ({
      chart: {
        title: "Consumption Trends",
        subtitle: "Product A",
        fontFamily: "Source Sans Pro",
      },
      series: {
        0: {
          color: globalStyles.customColors.flamePea,
          annotations: {
            stem: {
              color: globalStyles.customColors.osloGray,
            },
            style: "line",
            textStyle: {
              fontSize: fontSize,
              color: textColor,
              fontName: "Source Sans Pro",
              bold: false,
            },
          },
          pointSize: 6,
          pointShape: "circle",
        },
      },
      tooltip: {
        trigger: "both",
        showColorCode: true,
        textStyle: {
          fontSize: 12,
          color: textColor,
        },
      },
      legend: {
        position: "bottom",
        alignment: "start",
        textStyle: {
          color: globalStyles.customColors.osloGray,
          fontSize: 14,
          fontName: "Source Sans Pro",
        },
      },
      hAxis: {
        titleTextStyle: {
          color: globalStyles.customColors.osloGray,
          fontSize: 14,
          italic: false,
        },
        textStyle: {
          color: globalStyles.customColors.osloGray,
          fontSize: useFontSize,
          italic: false,
          fontName: "Source Sans Pro",
        },
        gridlines: {
          color: "transparent",
        },
        slantedText: false,
      },
      vAxis: {
        titleTextStyle: {
          color: globalStyles.customColors.osloGray,
          fontSize: 14,
          italic: false,
        },
        textStyle: {
          color: globalStyles.customColors.osloGray,
          fontSize: useFontSize,
          italic: false,
          fontName: "Source Sans Pro",
        },
        format: "€#,###.00",
        gridlines: {
          color: "rgba(163, 174, 208, 0.3)",
        },
        viewWindow: {
          min: minValue ?? 0, // Null coalescing operator
          max: maxValue ?? 100, // Null coalescing operator
        },
        minorGridlines: {
          count: 5,
        },
      },
      colors: [globalStyles.customColors.flamePea],
      backgroundColor: {
        fill: "transparent",
      },
      chartArea: chartAreaConfig ?? {
        left: 75,
        top: 40,
        right: 30,
        bottom: 60,
      },
      curveType: "none", // Use straight lines between data points
      lineWidth: 3,
      interpolateNulls: true, // Ensure missing data points don't break the line
      explorer: {
        actions: ["dragToZoom", "rightClickToReset"],
        axis: "horizontal",
        keepInBounds: true,
      },
    }),
    [minValue, maxValue, fontSize, textColor, useFontSize, chartAreaConfig]
  );

  return (
    <Card
      p="20px"
      alignItems="center"
      flexDirection="column"
      w="100%"
      h="100%"
      {...rest}
    >
      <Flex align="center" justify="space-between" w="100%" px="15px" py="10px">
        <Text
          color={textColor}
          fontSize="xl"
          fontWeight="700"
          lineHeight="100%"
        >
          Historical Value
        </Text>
        {!isMobile && (
          <Flex>
            <ButtonGroup size="sm" isAttached variant="outline">
              <Tooltip label="Show less historical data (4 quarters)">
                <Button
                  onClick={() => handleQuarterToggle(4)}
                  bg={
                    quartersToShow === 4
                      ? globalStyles.customColors.flamePea
                      : undefined
                  }
                  color={quartersToShow === 4 ? "white" : undefined}
                  fontWeight="normal"
                  _hover={{
                    bg:
                      quartersToShow === 4
                        ? globalStyles.customColors.flamePea
                        : "gray.100",
                  }}
                >
                  Less data
                </Button>
              </Tooltip>
              <Tooltip label="Show standard amount of data (8 quarters)">
                <Button
                  onClick={() => handleQuarterToggle(8)}
                  bg={
                    quartersToShow === 8
                      ? globalStyles.customColors.flamePea
                      : undefined
                  }
                  color={quartersToShow === 8 ? "white" : undefined}
                  fontWeight="normal"
                  _hover={{
                    bg:
                      quartersToShow === 8
                        ? globalStyles.customColors.flamePea
                        : "gray.100",
                  }}
                >
                  Default
                </Button>
              </Tooltip>
              <Tooltip label="Show more historical data (12 quarters)">
                <Button
                  onClick={() => handleQuarterToggle(12)}
                  bg={
                    quartersToShow === 12
                      ? globalStyles.customColors.flamePea
                      : undefined
                  }
                  color={quartersToShow === 12 ? "white" : undefined}
                  fontWeight="normal"
                  _hover={{
                    bg:
                      quartersToShow === 12
                        ? globalStyles.customColors.flamePea
                        : "gray.100",
                  }}
                >
                  More data
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Flex>
        )}
      </Flex>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Flex align="center" w="100%" h="400px">
            {dataReady && lineChartData && lineChartData.length >= 2 ? (
              <LineChart
                key={`chart-${currentPage}-${quartersToShow}`}
                chartData={lineChartData}
                chartOptions={{
                  ...LineChartOptionsConsumption,
                  // Optimize animations for maximally smooth transitions
                  animation: {
                    startup: false,
                    duration: 500,
                    easing: "inAndOut",
                  },
                  // Use straight lines between data points
                  curveType: "none",
                  // Increase line width slightly for better visibility
                  lineWidth: 3.5,
                  // Ensure smooth transitions with better interpolation
                  interpolateNulls: true,
                }}
                width="100%"
                height="400px"
              />
            ) : (
              <Flex justify="center" align="center" w="100%">
                <Spinner />
                <Text ml={3}>Loading chart data...</Text>
              </Flex>
            )}
          </Flex>

          {/* Wrap navigation controls in a memoized component to prevent rerenders */}
          <ChartNavigation
            currentPage={currentPage}
            maxPage={Math.max(0, allQuarters.length - quartersToShow)}
            dataReady={dataReady}
            onPrevious={handlePrevious}
            onNext={handleNext}
          />
        </>
      )}
    </Card>
  );
}
