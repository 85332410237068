import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Spinner, Box, Text, Center } from '@chakra-ui/react';

type ChartProps = {
  chartData: any[];
  chartOptions: any;
  width?: string | number;
  height?: string | number;
};

/**
 * Completely rewritten LineChart component that directly uses the Google Charts API
 * This avoids issues with the react-google-charts wrapper library
 */
const LineChart: React.FC<ChartProps> = ({chartData, chartOptions, width = '100%', height = '400px'}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstance = useRef<any>(null);
  const drawTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const resizeTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const dataRef = useRef(chartData); // Keep reference to latest data for resize handler
  const optionsRef = useRef(chartOptions); // Keep reference to latest options for resize handler
  
  // Update refs when props change
  useEffect(() => {
    dataRef.current = chartData;
    optionsRef.current = chartOptions;
  }, [chartData, chartOptions]);
  
  // Fix for the "Cannot read properties of undefined (reading 'Do')" error
  // This happens because the chart tries to access a domain object that doesn't exist
  useEffect(() => {
    // Define a patch for Google Charts
    const patchGoogleCharts = () => {
      if (window && (window as any).google && (window as any).google.visualization) {
        const originalPrototype = (window as any).google.visualization.LineChart.prototype;
        const originalDraw = originalPrototype.draw;
        
        // Replace the original draw method with a patched version
        if (originalDraw && !originalPrototype.__patched) {
          originalPrototype.draw = function(data: any, options: any) {
            // Ensure hAxis and vAxis exist with domain properties
            if (!options.hAxis) options.hAxis = {};
            if (!options.vAxis) options.vAxis = {};
            
            // Add empty domain objects to prevent the 'Do' error
            options.hAxis.domain = options.hAxis.domain || {};
            options.vAxis.domain = options.vAxis.domain || {};
            
            try {
              return originalDraw.apply(this, [data, options]);
            } catch (err) {
              console.error('Google Charts draw error:', err);
              return null;
            }
          };
          
          // Mark as patched to avoid double patching
          originalPrototype.__patched = true;
        }
      }
    };
    
    // Apply the patch when Google Charts is loaded
    const checkAndPatchGoogleCharts = setInterval(() => {
      if (window && (window as any).google && (window as any).google.visualization) {
        patchGoogleCharts();
        clearInterval(checkAndPatchGoogleCharts);
      }
    }, 100);
    
    return () => {
      clearInterval(checkAndPatchGoogleCharts);
    };
  }, []);
  
  // Load Google Charts API only once when component mounts
  useEffect(() => {
    let isUnmounted = false;
    
    const loadGoogleCharts = async () => {
      try {
        setIsLoading(true);
        setError(null);
        
        // Make sure Google Charts is loaded
        if (!(window as any).google || !(window as any).google.charts) {
          // Create script element to load Google Charts
          const script = document.createElement('script');
          script.src = 'https://www.gstatic.com/charts/loader.js';
          script.async = true;
          
          // Wait for the script to load
          await new Promise((resolve, reject) => {
            script.onload = resolve;
            script.onerror = () => reject(new Error('Failed to load Google Charts'));
            document.head.appendChild(script);
          });
        }
        
        if (isUnmounted) return;
        
        // Load the Google Charts library
        await new Promise((resolve) => {
          (window as any).google.charts.load('current', { packages: ['corechart'] });
          (window as any).google.charts.setOnLoadCallback(resolve);
        });
        
        if (isUnmounted) return;
        
        // Draw the chart
        drawChart(chartData, chartOptions);
      } catch (err) {
        if (!isUnmounted) {
          console.error('Error loading Google Charts:', err);
          setError('Failed to load Google Charts library');
          setIsLoading(false);
        }
      }
    };
    
    loadGoogleCharts();
    
    return () => {
      isUnmounted = true;
      // Clear any pending timeouts
      if (drawTimeoutRef.current) {
        clearTimeout(drawTimeoutRef.current);
      }
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
    };
  }, []);
  
  // Memoized draw chart function to reduce rerenders
  const drawChart = useCallback((data: any[], options: any) => {
    try {
      if (!chartRef.current || !data || data.length < 2) {
        setError('Invalid chart data');
        setIsLoading(false);
        return;
      }
      
      const chartData = (window as any).google.visualization.arrayToDataTable(data);
      
      // Clean up previous chart instance
      if (chartInstance.current) {
        chartInstance.current.clearChart();
      }
      
      // Create new chart instance if it doesn't exist
      if (!chartInstance.current) {
        chartInstance.current = new (window as any).google.visualization.LineChart(chartRef.current);
        
        // Add error handling
        (window as any).google.visualization.events.addListener(chartInstance.current, 'error', (err: any) => {
          console.error('Google Chart error:', err);
          setError('Chart error occurred');
          setIsLoading(false);
        });
        
        // Add ready listener
        (window as any).google.visualization.events.addListener(chartInstance.current, 'ready', () => {
          setIsLoading(false);
        });
      }
      
      // Safe options with default values for required properties
      const safeOptions = {
        // Default chart options
        chartArea: { width: '80%', height: '70%' },
        legend: { position: 'bottom' },
        // Merge with provided options
        ...options,
        // Ensure vAxis exists with safe viewWindow and domain
        vAxis: {
          viewWindow: { min: 0, max: 100 },
          domain: {}, // Add empty domain object to prevent 'Do' error
          ...(options?.vAxis || {}),
        },
        // Ensure hAxis exists with domain
        hAxis: {
          domain: {}, // Add empty domain object to prevent 'Do' error
          ...(options?.hAxis || {}),
        },
        // Optimize animations for maximum smoothness
        animation: {
          startup: false,
          duration: 500,     // Slightly longer duration for smoother effect
          easing: 'inAndOut', // More natural easing function
          ...(options?.animation || {})
        }
      };
      
      // Use requestAnimationFrame for smoother rendering
      requestAnimationFrame(() => {
        // Draw the chart
        chartInstance.current.draw(chartData, safeOptions);
      });
    } catch (err) {
      console.error('Error drawing chart:', err);
      setError('Error rendering chart');
      setIsLoading(false);
    }
  }, []);
  
  // Redraw chart when data or options change, with debouncing
  useEffect(() => {
    if (!(window as any).google || !(window as any).google.visualization) return;
    
    // Clear any existing timeout to prevent multiple redraws
    if (drawTimeoutRef.current) {
      clearTimeout(drawTimeoutRef.current);
    }
    
    // Set a style to improve browser rendering performance
    if (chartRef.current) {
      chartRef.current.style.willChange = 'transform';
    }
    
    // Debounce chart redraw to prevent excessive renders
    drawTimeoutRef.current = setTimeout(() => {
      drawChart(chartData, chartOptions);
      
      // Reset will-change after animation is likely complete
      setTimeout(() => {
        if (chartRef.current) {
          chartRef.current.style.willChange = 'auto';
        }
      }, 550); // Slightly longer than animation duration
      
    }, 100); // Shorter delay for more responsive updates
    
    return () => {
      if (drawTimeoutRef.current) {
        clearTimeout(drawTimeoutRef.current);
      }
    };
  }, [chartData, chartOptions, drawChart]);
  
  // Handle window resize with debouncing
  useEffect(() => {
    const handleResize = () => {
      if (chartInstance.current) {
        // Clear any existing resize timeout
        if (resizeTimeoutRef.current) {
          clearTimeout(resizeTimeoutRef.current);
        }
        
        // Debounce resize to prevent excessive renders
        resizeTimeoutRef.current = setTimeout(() => {
          try {
            // Use the refs to access latest data and options
            const data = (window as any).google.visualization.arrayToDataTable(dataRef.current);
            
            // Safe options with domain objects
            const safeOptions = {
              ...optionsRef.current,
              vAxis: {
                ...(optionsRef.current?.vAxis || {}),
                domain: {},
              },
              hAxis: {
                ...(optionsRef.current?.hAxis || {}),
                domain: {},
              },
              // Optimize resize animations
              animation: {
                startup: false,
                duration: 400,
                easing: 'inAndOut'
              }
            };
            
            // Use requestAnimationFrame for smoother rendering during resize
            requestAnimationFrame(() => {
              chartInstance.current.draw(data, safeOptions);
            });
          } catch (err) {
            console.error('Error redrawing chart on resize:', err);
          }
        }, 250); // 250ms debounce for resize events
      }
    };
    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
    };
  }, []); // Empty dependency array as we use refs for latest values
  
  if (error) {
    return (
      <Center h={height} w={width}>
        <Box textAlign="center">
          <Text color="red.500">Chart Error: {error}</Text>
          <Text mt={2} fontSize="sm">Please try refreshing the page</Text>
        </Box>
      </Center>
    );
  }
  
  return (
    <Box w={width} h={height} position="relative">
      {isLoading && (
        <Center position="absolute" top="0" left="0" w="100%" h="100%">
          <Spinner />
        </Center>
      )}
      <div 
        ref={chartRef} 
        style={{ 
          width: '100%', 
          height: '100%', 
          transform: 'translateZ(0)', // Hardware acceleration
          backfaceVisibility: 'hidden', // Helps with GPU rendering
        }} 
      />
    </Box>
  );
};

export default React.memo(LineChart); // Use memo to prevent unnecessary rerenders
