import { useSelector } from "react-redux";
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card";
import { RootState } from "../../../../redux/store";
import PieChart from "components/charts/PieChart";
import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import StrategyCalculator from "utils/StrategyCalculator";

interface ActualValueProps {}

const ActualValue: React.FC<ActualValueProps> = () => {
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const selectedDate = useSelector(
    (state: RootState) => state.data.selectedDate
  );
  const stakeholders = useSelector(
    (state: RootState) => state.data.stakeholders
  );
  const strategyMappingSettings = useSelector(
    (state: RootState) => state.data.strategyMappingSettings
  );
  const selectedStrategyIds = useSelector(
    (state: RootState) => state.data.selectedStrategyIds
  );
  const securityTypeMappingSettings = useSelector(
    (state: RootState) => state.data.securityTypeMappingSettings
  );

  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR",
      }),
    []
  );

  const strategyCalculator = useMemo(
    () => new StrategyCalculator(strategyMappingSettings),
    [strategyMappingSettings]
  );

  const [strategyValues, setStrategyValues] = useState<{
    [key: string]: number;
  }>();

  // Add state to track if the chart is ready/updated
  const [isChartReady, setIsChartReady] = useState(false);

  const calculationDate = useMemo(() => {
    return selectedDate ? new Date(selectedDate) : new Date();
  }, [selectedDate]);

  // This will check if we're showing only strategies from a single top-level group
  const showingOnlySingleTopLevelGroup = useMemo(() => {
    if (
      !selectedStrategyIds.length ||
      !strategyMappingSettings?.values?.length
    ) {
      return false;
    }

    // Get all selected strategy IDs and their top-level strategies
    const selectedStrategies = selectedStrategyIds
      .map((id) => {
        return {
          id,
          topLevel: strategyCalculator.getStrategyNameById(id),
        };
      })
      .filter((strategy) => strategy.topLevel); // Filter out any nulls

    if (selectedStrategies.length === 0) {
      return false;
    }

    // Check if all the selected strategies have the same top-level strategy
    const uniqueTopLevels = [
      ...new Set(selectedStrategies.map((s) => s.topLevel)),
    ];
    return uniqueTopLevels.length === 1;
  }, [selectedStrategyIds, strategyMappingSettings, strategyCalculator]);

  // This will check if we're showing only strategies from a single combined group
  const showingOnlySingleCombinedGroup = useMemo(() => {
    if (
      !selectedStrategyIds.length ||
      !strategyMappingSettings?.values?.length
    ) {
      return false;
    }

    // Get all selected strategy IDs and their top-level strategies
    const selectedStrategies = selectedStrategyIds
      .map((id) => {
        return {
          id,
          topLevel: strategyCalculator.getStrategyNameById(id),
          combined: strategyCalculator.getCombinedNameByStrategyId(id),
        };
      })
      .filter((strategy) => strategy.topLevel && strategy.combined); // Filter out any nulls

    if (selectedStrategies.length === 0) {
      return false;
    }

    // Check if all the selected strategies have the same top-level strategy
    const uniqueTopLevels = [
      ...new Set(selectedStrategies.map((s) => s.topLevel)),
    ];
    if (uniqueTopLevels.length !== 1) {
      return false;
    }

    // Check if all the selected strategies have the same combined strategy
    const uniqueCombined = [
      ...new Set(selectedStrategies.map((s) => s.combined)),
    ];
    return uniqueCombined.length === 1;
  }, [selectedStrategyIds, strategyMappingSettings, strategyCalculator]);

  // Add a function to get the current group name for the title
  const getCurrentGroupName = useMemo(() => {
    if (showingOnlySingleTopLevelGroup && selectedStrategyIds.length > 0) {
      // Get the first strategy ID and find its top-level name
      const strategyId = selectedStrategyIds[0];
      return strategyCalculator.getStrategyNameById(strategyId);
    }
    return null;
  }, [showingOnlySingleTopLevelGroup, selectedStrategyIds, strategyCalculator]);

  const pieChartData = useMemo(() => {
    const data: (string | number | object)[][] = [];
    data.push(["Strategy", "Actual Value", { role: "tooltip" }]);

    if (!strategyValues || !isChartReady) {
      return data;
    }

    // If we're showing only strategies from a single combined group, show detailed level
    if (showingOnlySingleCombinedGroup) {
      // Get the different investment types (SHARE, LOAN, etc.) in the selected combined group
      const detailedValues: { [key: string]: number } = {};

      stakeholders?.forEach((st) => {
        // Process shares
        st.shares?.forEach((share) => {
          if (!selectedStrategyIds.includes(share.strategyId)) {
            return;
          }

          const value = strategyCalculator.calculateActualShareValue(
            share,
            calculationDate,
            securityTypeMappingSettings.securityTypes ?? []
          );
          if (value) {
            const detailedName = strategyCalculator.getDetailedNameByStrategyId(
              share.strategyId,
              "SHARE"
            );
            if (detailedName) {
              if (!detailedValues[detailedName]) {
                detailedValues[detailedName] = 0;
              }
              detailedValues[detailedName] += value;
            }
          }
        });

        // Process loans
        // Get the most recent loan per strategy ID
        const actualLoans = _.chain(st.loans)
          .groupBy((x) => x.strategyId)
          .map((loans, strategyId) => ({
            strategyId,
            loan: _.maxBy(loans, (loan) => new Date(loan.startDate)),
          }))
          .value()
          .map((x) => x.loan);

        actualLoans?.forEach((loan) => {
          if (!selectedStrategyIds.includes(loan.strategyId)) {
            return;
          }
          if (new Date(loan.startDate).getTime() > calculationDate.getTime()) {
            return;
          }

          const loanValue = strategyCalculator.calculateActualLoanValue(
            loan,
            calculationDate
          );
          if (loanValue) {
            const detailedName = strategyCalculator.getDetailedNameByStrategyId(
              loan.strategyId,
              "LOAN"
            );
            if (detailedName) {
              if (!detailedValues[detailedName]) {
                detailedValues[detailedName] = 0;
              }
              detailedValues[detailedName] += loanValue;
            }
          }
        });
      });

      // Add detailed values to chart data
      Object.keys(detailedValues).forEach((strategyName) => {
        // For detailed level, if name is "-", use the combined group name instead
        const displayName =
          strategyName === "-"
            ? selectedStrategyIds.length > 0
              ? strategyCalculator.getCombinedNameByStrategyId(
                  selectedStrategyIds[0]
                ) || strategyName
              : strategyName
            : strategyName;

        data.push([
          displayName,
          detailedValues[strategyName],
          `${displayName}: ${currencyFormatter.format(
            detailedValues[strategyName]
          )}`,
        ]);
      });
    }
    // If we're showing only strategies from a single top-level group, show combined strategies
    else if (showingOnlySingleTopLevelGroup) {
      // Show individual combined strategies for this top-level group
      const combinedValues: { [key: string]: number } = {};

      stakeholders?.forEach((st) => {
        // Process shares
        st.shares?.forEach((share) => {
          if (!selectedStrategyIds.includes(share.strategyId)) {
            return;
          }

          const value = strategyCalculator.calculateActualShareValue(
            share,
            calculationDate,
            securityTypeMappingSettings.securityTypes ?? []
          );
          if (value) {
            // Get combined strategy name
            const combinedName = strategyCalculator.getCombinedNameByStrategyId(
              share.strategyId
            );
            if (combinedName) {
              if (!combinedValues[combinedName]) {
                combinedValues[combinedName] = 0;
              }
              combinedValues[combinedName] += value;
            }
          }
        });

        // Process loans
        const actualLoans = _.chain(st.loans)
          .groupBy((x) => x.strategyId)
          .map((loans, strategyId) => ({
            strategyId,
            loan: _.maxBy(loans, (loan) => new Date(loan.startDate)),
          }))
          .value()
          .map((x) => x.loan);

        actualLoans?.forEach((loan) => {
          if (!selectedStrategyIds.includes(loan.strategyId)) {
            return;
          }
          if (new Date(loan.startDate).getTime() > calculationDate.getTime()) {
            return;
          }

          const loanValue = strategyCalculator.calculateActualLoanValue(
            loan,
            calculationDate
          );
          if (loanValue) {
            // Get combined strategy name
            const combinedName = strategyCalculator.getCombinedNameByStrategyId(
              loan.strategyId
            );
            if (combinedName) {
              if (!combinedValues[combinedName]) {
                combinedValues[combinedName] = 0;
              }
              combinedValues[combinedName] += loanValue;
            }
          }
        });
      });

      // Add combined values to chart data
      Object.keys(combinedValues).forEach((strategyName) => {
        // For combined level, if name is "-", use the top-level group name instead
        const displayName =
          strategyName === "-"
            ? getCurrentGroupName || strategyName
            : strategyName;

        data.push([
          displayName,
          combinedValues[strategyName],
          `${displayName}: ${currencyFormatter.format(
            combinedValues[strategyName]
          )}`,
        ]);
      });
    }
    // Otherwise, show top-level strategies
    else {
      // Show grouped top-level strategies when multiple groups are selected
      const topLevelValues: { [key: string]: number } = {};

      stakeholders?.forEach((st) => {
        // Process shares
        st.shares?.forEach((share) => {
          if (!selectedStrategyIds.includes(share.strategyId)) {
            return;
          }

          const value = strategyCalculator.calculateActualShareValue(
            share,
            calculationDate,
            securityTypeMappingSettings.securityTypes ?? []
          );
          if (value) {
            // Get top-level strategy name
            const topLevelName = strategyCalculator.getStrategyNameById(
              share.strategyId
            );
            if (topLevelName) {
              if (!topLevelValues[topLevelName]) {
                topLevelValues[topLevelName] = 0;
              }
              topLevelValues[topLevelName] += value;
            }
          }
        });

        // Process loans
        const actualLoans = _.chain(st.loans)
          .groupBy((x) => x.strategyId)
          .map((loans, strategyId) => ({
            strategyId,
            loan: _.maxBy(loans, (loan) => new Date(loan.startDate)),
          }))
          .value()
          .map((x) => x.loan);

        actualLoans?.forEach((loan) => {
          if (!selectedStrategyIds.includes(loan.strategyId)) {
            return;
          }
          if (new Date(loan.startDate).getTime() > calculationDate.getTime()) {
            return;
          }

          const loanValue = strategyCalculator.calculateActualLoanValue(
            loan,
            calculationDate
          );
          if (loanValue) {
            // Get top-level strategy name
            const topLevelName = strategyCalculator.getStrategyNameById(
              loan.strategyId
            );
            if (topLevelName) {
              if (!topLevelValues[topLevelName]) {
                topLevelValues[topLevelName] = 0;
              }
              topLevelValues[topLevelName] += loanValue;
            }
          }
        });
      });

      // Add top-level values to chart data
      Object.keys(topLevelValues).forEach((strategyName) => {
        // Use the original strategy name as is (should already be top-level)
        data.push([
          strategyName,
          topLevelValues[strategyName],
          `${strategyName}: ${currencyFormatter.format(
            topLevelValues[strategyName]
          )}`,
        ]);
      });
    }

    return data;
  }, [
    strategyValues,
    isChartReady,
    showingOnlySingleCombinedGroup,
    showingOnlySingleTopLevelGroup,
    stakeholders,
    selectedStrategyIds,
    strategyCalculator,
    calculationDate,
    securityTypeMappingSettings,
    currencyFormatter,
    getCurrentGroupName,
  ]);

  // Add a reset effect when the selected strategies change
  useEffect(() => {
    // Reset chart state so it can be recalculated
    setIsChartReady(false);

    // Short delay to ensure state is updated properly
    const timer = setTimeout(() => {
      setIsChartReady(true);
    }, 50);

    return () => clearTimeout(timer);
  }, [selectedStrategyIds]);

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, []);

  // Modified to calculate values for top-level, combined, and detailed strategies
  useEffect(() => {
    const topLevelValues: { [key: string]: number } = {};
    const combinedStrategyValues: { [key: string]: number } = {};
    const detailedValues: { [key: string]: number } = {};

    stakeholders?.forEach((st) => {
      // Shares calculation
      st.shares?.forEach((share) => {
        if (!selectedStrategyIds.includes(share.strategyId)) {
          return;
        }
        const value = strategyCalculator.calculateActualShareValue(
          share,
          calculationDate,
          securityTypeMappingSettings.securityTypes ?? []
        );

        if (value) {
          // Get top-level, combined, and detailed strategy names
          const topLevelName = strategyCalculator.getStrategyNameById(
            share.strategyId
          );
          const combinedName = strategyCalculator.getCombinedNameByStrategyId(
            share.strategyId
          );
          const detailedName = strategyCalculator.getDetailedNameByStrategyId(
            share.strategyId,
            "SHARE"
          );

          if (topLevelName) {
            if (!topLevelValues[topLevelName]) {
              topLevelValues[topLevelName] = 0;
            }
            topLevelValues[topLevelName] += value;
          }

          if (combinedName) {
            if (!combinedStrategyValues[combinedName]) {
              combinedStrategyValues[combinedName] = 0;
            }
            combinedStrategyValues[combinedName] += value;
          }

          if (detailedName) {
            if (!detailedValues[detailedName]) {
              detailedValues[detailedName] = 0;
            }
            detailedValues[detailedName] += value;
          }
        }
      });

      // Process loans
      const actualLoans = _.chain(st.loans)
        .groupBy((x) => x.strategyId)
        .map((loans, strategyId) => ({
          strategyId,
          loan: _.maxBy(loans, (loan) => new Date(loan.startDate)),
        }))
        .value()
        .map((x) => x.loan);

      actualLoans?.forEach((loan) => {
        if (!selectedStrategyIds.includes(loan.strategyId)) {
          return;
        }
        if (new Date(loan.startDate).getTime() > calculationDate.getTime()) {
          return;
        }

        const loanValue = strategyCalculator.calculateActualLoanValue(
          loan,
          calculationDate
        );

        if (loanValue) {
          // Get top-level, combined, and detailed strategy names
          const topLevelName = strategyCalculator.getStrategyNameById(
            loan.strategyId
          );
          const combinedName = strategyCalculator.getCombinedNameByStrategyId(
            loan.strategyId
          );
          const detailedName = strategyCalculator.getDetailedNameByStrategyId(
            loan.strategyId,
            "LOAN"
          );

          if (topLevelName) {
            if (!topLevelValues[topLevelName]) {
              topLevelValues[topLevelName] = 0;
            }
            topLevelValues[topLevelName] += loanValue;
          }

          if (combinedName) {
            if (!combinedStrategyValues[combinedName]) {
              combinedStrategyValues[combinedName] = 0;
            }
            combinedStrategyValues[combinedName] += loanValue;
          }

          if (detailedName) {
            if (!detailedValues[detailedName]) {
              detailedValues[detailedName] = 0;
            }
            detailedValues[detailedName] += loanValue;
          }
        }
      });
    });

    // Use the appropriate values based on our display mode
    if (showingOnlySingleCombinedGroup) {
      setStrategyValues(detailedValues);
    } else if (showingOnlySingleTopLevelGroup) {
      setStrategyValues(combinedStrategyValues);
    } else {
      setStrategyValues(topLevelValues);
    }

    setIsChartReady(true);
  }, [
    selectedStrategyIds,
    stakeholders,
    strategyCalculator,
    calculationDate,
    showingOnlySingleCombinedGroup,
    showingOnlySingleTopLevelGroup,
    securityTypeMappingSettings,
  ]);

  return (
    <Card alignItems="center" flexDirection="column" w="100%" h="100%">
      <Flex direction="column" align="flex-start" w="100%" px="15px" py="10px">
        <Text
          me="auto"
          color={textColor}
          fontSize="xl"
          fontWeight="700"
          lineHeight="100%"
        >
          Actual Value
        </Text>
        <Text
          me="auto"
          color={textColor}
          fontSize="sm"
          fontWeight="500"
          mt="4px"
          opacity="0.7"
        >
          {showingOnlySingleCombinedGroup
            ? `Detailed view by investment within ${
                strategyCalculator.getCombinedNameByStrategyId(
                  selectedStrategyIds[0]
                ) || "combined group"
              }`
            : showingOnlySingleTopLevelGroup
            ? `Showing strategies within ${getCurrentGroupName || "group"}`
            : "Grouped by investment categories"}
        </Text>
      </Flex>
      {isChartReady && <PieChart chartData={pieChartData} />}
    </Card>
  );
};

export default ActualValue;
